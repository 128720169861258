import React, { useState, useEffect } from "react";
import { Cookies } from "react-cookie";
import { VictoryChart, VictoryLine, VictoryAxis, VictoryLabel, VictoryTooltip, VictoryVoronoiContainer } from "victory";

import LogoSvg from "../svgs/Logo";
import { rangeToTime } from "../helpers/time";

const statColorMap = {
    inventory: "#65638A",
    balance: "#06A1F8",
    total: "#26D07F",
};

function AssetsChart() {
    const [statisticsRange, setStatisticsRange] = useState("7d");
    const [chartStatistics, setChartStatistics] = useState(["inventory", "balance", "total"]);
    const [chartData, setChartData] = useState({ inventory: [], balance: [], inventoryReturn: [] });
    const [period, setPeriod] = useState("hourly"); // ["daily", "weekly", "monthly"
    const cookies = new Cookies();
    const token = cookies.get("token");

    const [isLoading, setIsLoading] = useState(false);

    const getChartMax = () => {
        let max = 0;
        Object.keys(chartData).forEach((type) => {
            const typeMax = Math.max(...chartData[type].map((data) => data.y));
            if (typeMax > max) {
                max = typeMax;
            }
        });
        max = Math.ceil(max / 1000) * 1000;
        return max;
    };

    const getChartMin = () => {
        let min = 0;
        Object.keys(chartData).forEach((type) => {
            const typeMin = Math.min(...chartData[type].map((data) => data.y));
            if (typeMin < min) {
                min = typeMin;
            }
        });
        min = Math.floor(min / 1000) * 1000;
        return Math.min(min, 0);
    };

    const getTickValues = () => {
        let min = getChartMin();
        let max = getChartMax();
        let tickValues = [];
        for (let i = min; i <= max; i += 100000) {
            tickValues.push(i);
        }
        return tickValues;
    };
    const getDate = (dateString) => {
        const splitDate = dateString.split("-");
        //date format is yyyy-mm-dd-hr
        let date = new Date(splitDate[0], splitDate[1] - 1);
        if (splitDate[2]) {
            date.setDate(splitDate[2]);
        }
        if (splitDate[3]) {
            date.setHours(splitDate[3]);
        }
        return date;
    };
    const getChart = async () => {
        try {
            setIsLoading(true);
            const url = process.env.REACT_APP_TRANSACTIONS_API_BASE_URL + "/v1/inventory/overview";
            let query = `?after=${rangeToTime(statisticsRange)}}`;
            query += `&stat=price`;
            query += `&period=${period}`;
            const res = await fetch(url + query, {
                method: "GET",
                headers: {
                    "x-api-key": cookies.get("token"),
                },
            });
            const reqData = await res.json();
            const { success, data } = reqData;
            if (success) {
                const newChartData = { inventory: [], balance: [], total: [] };
                for (const date of Object.keys(data)) {
                    newChartData.inventory.push({ x: getDate(date), y: data[date].inventoryValue / 100 });
                    newChartData.balance.push({ x: getDate(date), y: data[date].balance / 100 });
                    newChartData.total.push({ x: getDate(date), y: (data[date].inventoryValue + data[date].balance) / 100 });
                }
                setChartData(newChartData);
            }
            return [];
        } catch (error) {
            return [];
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getChart();
    }, [token, statisticsRange]);

    return (
        <div className="panel-section">
            <div className="panel-section-header">Assets</div>
            <div className="panel-section-selection">
                <div className="panel-section-selection-left">
                    <div className="panel-section-selection-header">Time Period:</div>
                    <div
                        className={`panel-section-button ${statisticsRange === "7d" ? "active" : ""}`}
                        onClick={() => {
                            setStatisticsRange("7d");
                            setPeriod("hourly");
                        }}
                    >
                        7d
                    </div>
                    <div
                        className={`panel-section-button ${statisticsRange === "30d" ? "active" : ""}`}
                        onClick={() => {
                            setStatisticsRange("30d");
                            setPeriod("daily");
                        }}
                    >
                        30d
                    </div>
                    <div
                        className={`panel-section-button ${statisticsRange === "Mtd" ? "active" : ""}`}
                        onClick={() => {
                            setStatisticsRange("Mtd");
                            setPeriod("daily");
                        }}
                    >
                        Mtd
                    </div>
                    <div
                        className={`panel-section-button ${statisticsRange === "All-time" ? "active" : ""}`}
                        onClick={() => {
                            setStatisticsRange("All-time");
                            setPeriod("monthly");
                        }}
                    >
                        All-Time
                    </div>
                </div>

                <div className="panel-section-selection-right">
                    <div className="chart-stats">
                        {toggleStat("inventory", chartStatistics, setChartStatistics)}
                        {toggleStat("balance", chartStatistics, setChartStatistics)}
                        {toggleStat("total", chartStatistics, setChartStatistics)}
                    </div>
                    <div className="panel-section-button" onClick={getChart}>
                        Refresh
                    </div>
                </div>
            </div>
            <div className="chart">
                {isLoading ? (
                    <div className="chart-loading">
                        <LogoSvg />
                    </div>
                ) : (
                    <>
                        <VictoryChart
                            style={{ parent: { maxWidth: "100%" } }}
                            width={1300}
                            height={660}
                            scale={{ x: "time" }}
                            containerComponent={
                                <VictoryVoronoiContainer
                                    voronoiDimension="x"
                                    labels={({ datum }) => {
                                        let value = datum.y;
                                        let stat = Object.keys(statColorMap).find((key) => statColorMap[key] == datum.style.data.stroke);
                                        return `Total ${uppercaseWord(stat)}: $${value.toLocaleString()}`;
                                    }}
                                    labelComponent={
                                        <VictoryTooltip
                                            cornerRadius={2}
                                            flyoutStyle={{
                                                fill: "white",
                                                border: "1px solid rgba(255, 255, 255, 0.10)",
                                                background: "#FFF",
                                                backdropFilter: "blur(5px)",
                                            }}
                                        />
                                    }
                                />
                            }
                        >
                            {Object.keys(chartData).map((type) => {
                                return chartStatistics.includes(type) && <VictoryLine data={chartData[type]} style={{ data: { stroke: `${statColorMap[type]}`, strokeWidth: "1px" } }} />;
                            })}
                            <VictoryAxis
                                offsetY={50}
                                tickCount={Math.min(chartData.inventory.length, 7)}
                                style={{
                                    tickLabels: { fill: "#7F7F7F" },
                                    grid: { stroke: "#FFFFFF1A", strokeWidth: 0.5, strokeDasharray: "6 6" },
                                }}
                            />
                            <VictoryAxis
                                dependentAxis
                                tickFormat={(x) => `$${x.toLocaleString(undefined, { maximumFractionDigits: 0 })}`}
                                style={{ tickLabels: { fill: "#F2F2F2" }, grid: { stroke: "#FFFFFF1A", strokeWidth: 0.5 } }}
                                domain={[getChartMin(), getChartMax()]}
                                tickCount={Math.ceil(Math.abs(getChartMax()) + Math.abs(getChartMin())) / 2000}
                                tickValues={getTickValues()}
                            />
                        </VictoryChart>
                    </>
                )}
            </div>
        </div>
    );
}

function toggleStat(stat, chartStatistics, setChartStatistics) {
    return (
        <div className="chart-stat" onClick={() => handleToggleStat(stat, chartStatistics, setChartStatistics)}>
            <div className="chart-stat-button">
                <div className="chart-stat-inner" style={{ backgroundColor: chartStatistics.includes(stat) ? statColorMap[stat] : "" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                        <path d="M2.24121 7.12695L4.81152 9.69727L9.95215 4.18945" stroke="#14131F" stroke-width="1.10156" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            </div>
            <div className="chart-stat-text">{uppercaseWord(stat)}</div>
        </div>
    );
}

function handleToggleStat(stat, chartStatistics, setChartStatistics) {
    if (chartStatistics.includes(stat)) {
        setChartStatistics(chartStatistics.filter((s) => s !== stat));
    } else {
        setChartStatistics([...chartStatistics, stat]);
    }
}

function uppercaseWord(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export default AssetsChart;
