function TwoFaSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path
                d="M16.5 9.8V8C16.5 6.80653 16.0259 5.66193 15.182 4.81802C14.3381 3.97411 13.1935 3.5 12 3.5C10.8066 3.5 9.66198 3.97411 8.81807 4.81802C7.97415 5.66193 7.50005 6.80653 7.50005 8V9.8C6.78396 9.8 6.09721 10.0845 5.59086 10.5908C5.08451 11.0972 4.80005 11.7839 4.80005 12.5V18.8C4.80005 19.5161 5.08451 20.2028 5.59086 20.7092C6.09721 21.2155 6.78396 21.5 7.50005 21.5H16.5C17.2161 21.5 17.9029 21.2155 18.4092 20.7092C18.9156 20.2028 19.2 19.5161 19.2 18.8V12.5C19.2 11.7839 18.9156 11.0972 18.4092 10.5908C17.9029 10.0845 17.2161 9.8 16.5 9.8ZM9.30005 8C9.30005 7.28392 9.58451 6.59716 10.0909 6.09081C10.5972 5.58446 11.284 5.3 12 5.3C12.7161 5.3 13.4029 5.58446 13.9092 6.09081C14.4156 6.59716 14.7 7.28392 14.7 8V9.8H9.30005V8ZM17.4 18.8C17.4 19.0387 17.3052 19.2676 17.1364 19.4364C16.9677 19.6052 16.7387 19.7 16.5 19.7H7.50005C7.26135 19.7 7.03244 19.6052 6.86365 19.4364C6.69487 19.2676 6.60005 19.0387 6.60005 18.8V12.5C6.60005 12.2613 6.69487 12.0324 6.86365 11.8636C7.03244 11.6948 7.26135 11.6 7.50005 11.6H16.5C16.7387 11.6 16.9677 11.6948 17.1364 11.8636C17.3052 12.0324 17.4 12.2613 17.4 12.5V18.8Z"
                fill="#D2E214"
            />
        </svg>
    );
}

export default TwoFaSvg;
