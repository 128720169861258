import React from "react";
import { formatDate } from "../helpers/time";
import { phaseToAbbreviation, extractCondition } from "../helpers/item";
import SiteIcon from "./SiteIcon";
import AccountIcon from "./AccountIcon";
import ItemIcon from "./ItemIcon";
import { NavLink } from "react-router-dom";
function TransactionsTable(props) {
    const { isLoading, columns, rows, pages, pageSize, currPage, setCurrPage, sort, setSort, sortBy, setSortBy, siteImages } = props;

    return (
        <div className="table">
            <div className="table-header">
                {columns.map((column) => {
                    return (
                        <div
                            className="table-header-column"
                            style={{ width: column.width }}
                            onClick={() => {
                                if (column.sortable) {
                                    if (sortBy === column.key) {
                                        setSort(sort === "asc" ? "desc" : "asc");
                                    } else {
                                        setSort("desc");
                                        setSortBy(column.key);
                                    }
                                }
                            }}
                        >
                            {column.name}
                            {sortBy === column.key && sort && <div className="sort">{sort === "asc" ? "▲" : "▼"}</div>}
                        </div>
                    );
                })}
            </div>
            {isLoading ? (
                <div className="table-body">
                    {Array.from({ length: pageSize }, (_, index) => (
                        <div className="row row-loading" key={index}>
                            {/* Add row content here */}
                        </div>
                    ))}
                </div>
            ) : (
                <div className="table-body">
                    {rows.map((row) => {
                        const { steamId, iconUrl, name, phase, purchaseLocation, purchasePrice, purchaseTime, saleLocation, salePrice, saleTime, profit } = row;
                        const [parsedName, parsedNameShort, parsedCondition, parsedConditionShort] = extractCondition(name);
                        return (
                            <div className="row">
                                <div style={{ width: columns[0].width }}>
                                    <AccountIcon name={steamId} iconUrl={`${process.env.REACT_APP_AUTH_API_BASE_URL}/logos/${steamId}.jpg`} />
                                </div>
                                <NavLink className="row-item" style={{ width: columns[1].width }} to={`/panel/item/${name}/${phase}`}>
                                    <ItemIcon name={name} iconUrl={iconUrl} />
                                    <div className="white">{parsedNameShort}</div>
                                    {phase && <div className="white">({phaseToAbbreviation(phase)})</div>}
                                    {parsedConditionShort && <div className="green">({parsedConditionShort})</div>}
                                </NavLink>

                                <div className="row-item" style={{ width: columns[2].width }}>
                                    <SiteIcon name={purchaseLocation} iconUrl={siteImages[purchaseLocation]} />
                                    {saleLocation && <SiteIcon name={saleLocation} iconUrl={siteImages[saleLocation]} />}
                                </div>
                                <div className="row-item" style={{ width: columns[3].width }}>
                                    ${(purchasePrice / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                </div>
                                <div className="row-item" style={{ width: columns[4].width }}>
                                    {formatDate(purchaseTime)}
                                </div>
                                <div className="row-item" style={{ width: columns[5].width }}>
                                    {salePrice && <div>${(salePrice / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>}
                                </div>
                                <div className="row-item" style={{ width: columns[6].width }}>
                                    {saleTime && <div>{formatDate(saleTime)}</div>}
                                </div>
                                <div className="row-item" style={{ width: columns[7].width }}>
                                    {saleTime && (
                                        <>
                                            <div className="white">${(profit / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
                                            <div className="green">({((salePrice / purchasePrice) * 100 - 100).toFixed(2)}%)</div>
                                        </>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
            <div className="table-footer">
                <div className="table-footer-page-selector">
                    <div
                        className="table-footer-button"
                        onClick={() => {
                            if (currPage - 1 < 1) return;
                            setCurrPage(currPage - 1);
                        }}
                    >
                        Prev
                    </div>
                    {GeneratePages(currPage, pages).map((page) => {
                        return (
                            <div className={`table-footer-button${page === currPage ? "-active" : ""}`} onClick={() => setCurrPage(page)}>
                                {page}
                            </div>
                        );
                    })}
                    <div
                        className={`table-footer-button${pages === currPage ? "-active" : ""}`}
                        onClick={() => {
                            setCurrPage(pages);
                        }}
                    >
                        {pages}
                    </div>
                    <input
                        className="table-footer-input"
                        placeholder="..."
                        onBlur={(event) => {
                            const pageNumber = parseInt(event.target.value);
                            if (pageNumber >= 1 && pageNumber <= pages) {
                                setCurrPage(pageNumber);
                            }
                            event.target.value = "";
                        }}
                    ></input>
                    <div
                        className="table-footer-button"
                        onClick={() => {
                            if (currPage - 1 > pages) return;
                            setCurrPage(currPage + 1);
                        }}
                    >
                        Next
                    </div>
                </div>
            </div>
        </div>
    );
}

function GeneratePages(currPage, totalPages) {
    const pages = [currPage - 2, currPage - 1, currPage, currPage + 1, currPage + 2];
    return pages.filter((page) => page >= 1 && page < totalPages);
}

export default TransactionsTable;
