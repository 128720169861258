function SoldSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
            <path
                d="M20.3502 12.3634C20.2751 12.4386 20.1858 12.4983 20.0876 12.539C19.9894 12.5797 19.8841 12.6007 19.7777 12.6007C19.6714 12.6007 19.5661 12.5797 19.4678 12.539C19.3696 12.4983 19.2803 12.4386 19.2052 12.3634L13.304 6.46111V22.3107C13.304 22.5253 13.2187 22.7311 13.0669 22.8829C12.9152 23.0346 12.7094 23.1199 12.4947 23.1199C12.2801 23.1199 12.0743 23.0346 11.9225 22.8829C11.7708 22.7311 11.6855 22.5253 11.6855 22.3107V6.46111L5.78429 12.3634C5.63245 12.5152 5.42651 12.6005 5.21177 12.6005C4.99703 12.6005 4.79109 12.5152 4.63925 12.3634C4.4874 12.2115 4.4021 12.0056 4.4021 11.7908C4.4021 11.5761 4.4874 11.3701 4.63925 11.2183L11.9222 3.93533C11.9974 3.86009 12.0866 3.80041 12.1849 3.75968C12.2831 3.71896 12.3884 3.698 12.4947 3.698C12.6011 3.698 12.7064 3.71896 12.8046 3.75968C12.9029 3.80041 12.9921 3.86009 13.0673 3.93533L20.3502 11.2183C20.4255 11.2935 20.4852 11.3827 20.5259 11.4809C20.5666 11.5792 20.5876 11.6845 20.5876 11.7908C20.5876 11.8972 20.5666 12.0025 20.5259 12.1007C20.4852 12.1989 20.4255 12.2882 20.3502 12.3634Z"
                fill="#D2E214"
            />
        </svg>
    );
}

function PurchasedSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
            <path
                d="M4.63902 13.5607C4.71417 13.4855 4.80342 13.4258 4.90166 13.3851C4.9999 13.3443 5.1052 13.3234 5.21154 13.3234C5.31788 13.3234 5.42319 13.3443 5.52143 13.3851C5.61966 13.4258 5.70891 13.4855 5.78406 13.5607L11.6853 19.463L11.6853 3.61339C11.6853 3.39878 11.7706 3.19295 11.9223 3.04119C12.0741 2.88943 12.2799 2.80417 12.4945 2.80417C12.7091 2.80417 12.915 2.88943 13.0667 3.04119C13.2185 3.19295 13.3037 3.39878 13.3037 3.61339L13.3037 19.463L19.205 13.5607C19.3568 13.4089 19.5628 13.3236 19.7775 13.3236C19.9922 13.3236 20.1982 13.4089 20.35 13.5607C20.5019 13.7126 20.5872 13.9185 20.5872 14.1332C20.5872 14.348 20.5019 14.5539 20.35 14.7058L13.067 21.9887C12.9919 22.064 12.9026 22.1237 12.8044 22.1644C12.7062 22.2051 12.6009 22.2261 12.4945 22.2261C12.3882 22.2261 12.2829 22.2051 12.1846 22.1644C12.0864 22.1237 11.9971 22.064 11.922 21.9887L4.63902 14.7058C4.56378 14.6306 4.50409 14.5414 4.46337 14.4431C4.42264 14.3449 4.40168 14.2396 4.40168 14.1332C4.40168 14.0269 4.42264 13.9216 4.46337 13.8234C4.50409 13.7251 4.56378 13.6359 4.63902 13.5607Z"
                fill="#D2E214"
            />
        </svg>
    );
}

function ProfitSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
            <path
                d="M6.00449 4.5979L2.79077 7.35252L6.00449 10.5662"
                stroke="#D2E214"
                stroke-width="1.83641"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M2.79077 7.35248H13.3474C16.5074 7.35248 19.1908 9.93263 19.3139 13.0913C19.4443 16.4289 16.6864 19.2891 13.3474 19.2891H5.54447"
                stroke="#D2E214"
                stroke-width="1.83641"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}

function PercentProfitSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path
                d="M7.6314 3.7948C5.62598 3.7948 3.99463 5.42615 3.99463 7.43157C3.99463 9.43699 5.62598 11.0683 7.6314 11.0683C9.63682 11.0683 11.2682 9.43699 11.2682 7.43157C11.2682 5.42615 9.63682 3.7948 7.6314 3.7948ZM7.6314 8.99019C6.76897 8.99019 6.07278 8.29401 6.07278 7.43157C6.07278 6.56914 6.76897 5.87296 7.6314 5.87296C8.49384 5.87296 9.19002 6.56914 9.19002 7.43157C9.19002 8.29401 8.49384 8.99019 7.6314 8.99019ZM16.9831 13.1465C14.9777 13.1465 13.3463 14.7779 13.3463 16.7833C13.3463 18.7887 14.9777 20.42 16.9831 20.42C18.9885 20.42 20.6199 18.7887 20.6199 16.7833C20.6199 14.7779 18.9885 13.1465 16.9831 13.1465ZM16.9831 18.3419C16.1207 18.3419 15.4245 17.6457 15.4245 16.7833C15.4245 15.9208 16.1207 15.2247 16.9831 15.2247C17.8455 15.2247 18.5417 15.9208 18.5417 16.7833C18.5417 17.6457 17.8455 18.3419 16.9831 18.3419ZM19.8821 4.53255C20.2874 4.93779 20.2874 5.5924 19.8821 5.99764L6.19747 19.6823C6.10127 19.7785 5.98707 19.8548 5.86138 19.9069C5.73569 19.9589 5.60097 19.9857 5.46492 19.9857C5.32888 19.9857 5.19416 19.9589 5.06847 19.9069C4.94278 19.8548 4.82857 19.7785 4.73237 19.6823C4.63617 19.5861 4.55986 19.4719 4.5078 19.3462C4.45574 19.2205 4.42894 19.0858 4.42894 18.9497C4.42894 18.8137 4.45574 18.679 4.5078 18.5533C4.55986 18.4276 4.63617 18.3134 4.73237 18.2172L18.417 4.53255C18.5132 4.43622 18.6273 4.3598 18.753 4.30765C18.8787 4.25551 19.0135 4.22867 19.1496 4.22867C19.2857 4.22867 19.4204 4.25551 19.5461 4.30765C19.6718 4.3598 19.786 4.43622 19.8821 4.53255Z"
                fill="#D2E214"
            />
        </svg>
    );
}

export { SoldSvg, PurchasedSvg, ProfitSvg, PercentProfitSvg };
