import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Route, Redirect, withRouter } from "react-router-dom";
import { Cookies } from "react-cookie";
import Panel from "./pages/Panel";
import Login from "./pages/Login";

function App() {
    const isLoggedIn = checkCookie(); // Function to check if user is logged in using a cookie

    return (
        <Router>
            <Route
                exact
                path="/"
                render={() => {
                    return isLoggedIn ? <Redirect to="/panel/home" /> : <Redirect to="/login" />;
                }}
            />
            <Route exact path="/login" component={Login} />
            <Route path="/panel" component={withRouter(Panel)} />
        </Router>
    );
}

function checkCookie() {
    const cookies = new Cookies();
    const token = cookies.get("token");
    return token !== undefined;
}
export default App;
