import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Cookies } from "react-cookie";
import { Tradable, Untradable } from "../svgs/Tradable";
import { LeftArrow } from "../svgs/Arrows";
import { extractCondition, toDollarFormat, getImageUrl } from "../helpers/item";
import { formatDate } from "../helpers/time";
import { NavLink } from "react-router-dom";
import { LowestPriceSvg } from "../svgs/ItemPage";
import PriceChart from "../components/PriceChart";
import ItemCard from "../components/ItemCard";

function InventoryItem({ siteImages }) {
    const { assetId } = useParams();
    console.log("Asset ID: ", assetId);
    const [item, setItem] = useState({});
    const [isLoadingItem, setIsLoadingItem] = useState(false);
    const [similarItems, setSimilarItems] = useState([]);

    const cookies = new Cookies();
    const token = cookies.get("token");

    const getItem = async () => {
        try {
            if (isLoadingItem) return;
            setIsLoadingItem(true);
            const url = process.env.REACT_APP_TRANSACTIONS_API_BASE_URL + "/v1/inventory/item/" + assetId;
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "x-api-key": token,
                },
            });
            const reqData = await res.json();
            const { success, data } = reqData;
            if (success) {
                setItem(data);
                const urlTwo = process.env.REACT_APP_TRANSACTIONS_API_BASE_URL + "/v1/inventory";
                let query = `?name=${data?.skin?.name}&pageSize=6&page=1`;
                const res = await fetch(urlTwo + query, {
                    method: "GET",
                    headers: {
                        "x-api-key": token,
                    },
                });
                const reqDataTwo = await res.json();
                const { success: successTwo, data: dataTwo } = reqDataTwo;
                if (successTwo) {
                    setSimilarItems(dataTwo?.inventory);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingItem(false);
        }
    };

    useEffect(() => {
        getItem();
    }, [token]);

    let [parsedName, parsedNameShort, parsedCondition, parsedConditionShort] = extractCondition(item?.skin?.name);

    return (
        <>
            {(item && (
                <div className="panel-wrapper">
                    <div className="item-header">
                        <div className="item-header-left">
                            <NavLink className="panel-section-button" style={{ width: "175px" }} to="/panel/inventory">
                                <LeftArrow />
                                Back to Inventory
                            </NavLink>
                            <div className="item-header-name">
                                {parsedName}{" "}
                                <div className="item-header-condition">
                                    {parsedCondition}
                                    {item?.skin?.phase ? " · " : null}
                                    {item?.skin?.phase}
                                </div>
                            </div>
                            <div className="item-header-image-container">
                                <div className="item-header-image-top-info">
                                    {(item?.tradable && (
                                        <div className="green-background">
                                            <Tradable />
                                        </div>
                                    )) || (
                                        <div className="red-background">
                                            <Untradable />
                                        </div>
                                    )}
                                </div>
                                <img className="item-header-image" src={getImageUrl(item?.skin?.iconUrl)} />
                            </div>
                            <div className="item-header-stats">
                                <div className="item-header-stat">
                                    <div className="item-header-stat-title">Worth</div>
                                    <div className="item-header-stat-value">{toDollarFormat(item?.skin?.fmv)}</div>
                                </div>
                                <div className="item-header-stat">
                                    <div className="item-header-stat-title">Lowest Sale Price</div>
                                    <div className="item-header-stat-value">{toDollarFormat(item?.skin?.sellPrice)}</div>
                                </div>
                                {item?.transaction?.purchasePrice && (
                                    <div className="item-header-stat">
                                        <div className="item-header-stat-title">Purchase Price</div>
                                        <div className="item-header-stat-value">{toDollarFormat(item?.transaction?.purchasePrice)}</div>
                                    </div>
                                )}
                                {item?.transaction?.purchaseTime && (
                                    <div className="item-header-stat">
                                        <div className="item-header-stat-title">Purchase Time</div>
                                        <div className="item-header-stat-value">{formatDate(item?.transaction?.purchaseTime)}</div>
                                    </div>
                                )}
                                {item?.transaction?.salePrice && (
                                    <div className="item-header-stat">
                                        <div className="item-header-stat-title">Sale Price</div>
                                        <div className="item-header-stat-value">{toDollarFormat(item?.transaction?.salePrice)}</div>
                                    </div>
                                )}
                                {item?.transaction?.saleTime && (
                                    <div className="item-header-stat">
                                        <div className="item-header-stat-title">Sale Time</div>
                                        <div className="item-header-stat-value">{formatDate(item?.transaction?.saleTime)}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="item-header-right">
                            <div className="item-header-listed">
                                <div className="item-header-listed-header">Listed On</div>
                                {item?.listings?.map((listing) => {
                                    if (!listing?.site) return;
                                    if (!listing.ourPrice) return;
                                    return (
                                        <div className="item-header-listed-site">
                                            <div className="item-header-listed-site-left">
                                                <img className="item-header-listed-site-image" src={siteImages[listing?.site]} />
                                                <div className="item-header-listed-site-name">{listing?.site}</div>
                                            </div>
                                            <div className="item-header-listed-site-right">
                                                {listing?.lowestPrice && (
                                                    <div className="inventory-item-bottom-listed-price">
                                                        <LowestPriceSvg /> {toDollarFormat(listing?.lowestPrice)}{" "}
                                                    </div>
                                                )}
                                                <div className="inventory-item-bottom-listed-price">{toDollarFormat(listing?.ourPrice)}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <PriceChart name={item?.skin?.name} phase={item?.skin?.phase} purchaseTime={item?.transaction?.purchaseTime} purchasePrice={item?.transaction?.purchasePrice} />
                    <div className="panel-section">
                        <div className="panel-section-selection">
                            <div className="panel-section-selection-left">
                                <div className="panel-section-selection-header">More of this Item</div>
                            </div>
                            <div className="panel-section-selection-right">
                                <div className="panel-section-button">View all</div>
                            </div>
                        </div>
                        <div className="inventory">
                            {/* {isLoadingItem ? (
                                <>
                                    {Array.from({ length: 6 }, (_, index) => (
                                        <InventoryItem loading={true} />
                                    ))}
                                </>
                            ) : (
                                <>
                                    {similarItems.map((item) => {
                                        return (
                                            <ItemCard
                                                loading={false}
                                                siteImages={siteImages}
                                                assetId={item?.assetId}
                                                iconUrl={item?.skin?.iconUrl}
                                                name={item?.skin?.name}
                                                phase={item?.skin?.phase}
                                                tradable={item?.tradable}
                                                tradableDate={item?.tradableDate}
                                                price={item?.skin?.fmv}
                                                purchasePrice={item?.transaction?.purchasePrice}
                                                listings={item?.listings}
                                            />
                                        );
                                    })}
                                </>
                            )} */}
                        </div>
                    </div>
                </div>
            )) || <div>Loading...</div>}
        </>
    );
}

export default InventoryItem;
