function EmailSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path
                d="M20.5 7.39995C20.5 6.46495 19.735 5.69995 18.8 5.69995H5.2C4.265 5.69995 3.5 6.46495 3.5 7.39995V17.6C3.5 18.535 4.265 19.3 5.2 19.3H18.8C19.735 19.3 20.5 18.535 20.5 17.6V7.39995ZM18.8 7.39995L12 11.65L5.2 7.39995H18.8ZM18.8 17.6H5.2V9.09995L12 13.35L18.8 9.09995V17.6Z"
                fill="#D2E214"
            />
        </svg>
    );
}

export default EmailSvg;
