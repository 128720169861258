import React, { useState, useEffect } from "react";
import { Cookies } from "react-cookie";

import { Tradable, Untradable } from "../svgs/Tradable";
import { LeftArrow, RightArrow } from "../svgs/Arrows";
import { PanelRowSearch, PanelRowSort } from "../svgs/PanelRow";
import ItemCard from "../components/ItemCard";

function Inventory({ siteImages }) {
    const [tradableValue, setTradableValue] = useState(0);
    const [tradableItems, setTradableItems] = useState(0);
    const [untradableValue, setUntradableValue] = useState(0);
    const [untradableItems, setUntradableItems] = useState(0);
    const [inventory, setInventory] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [sortBy, setSortBy] = useState("price");
    const [sort, setSort] = useState("desc");
    const [nameSearch, setNameSearch] = useState("");
    const [tradable, setTradable] = useState("all");

    const pageSize = 12;

    const cookies = new Cookies();
    const token = cookies.get("token");

    const getInventory = async () => {
        try {
            if (isLoading) return;
            setIsLoading(true);
            const url = process.env.REACT_APP_TRANSACTIONS_API_BASE_URL + "/v1/inventory";

            let query = `?page=${page}`;
            query += `&pageSize=${pageSize}`;
            query += `&sortBy=${sortBy}`;
            query += `&sort=${sort}`;
            if (nameSearch) query += `&name=${nameSearch}`;
            if (tradable !== "all") query += `&tradable=${tradable}`;
            const res = await fetch(url + query, {
                method: "GET",
                headers: {
                    "x-api-key": token,
                },
            });
            const reqData = await res.json();
            const { success, data } = reqData;
            if (success) {
                setInventory(data?.inventory);
                setTradableValue(data?.tradableValue);
                setTradableItems(data?.tradableItems);
                setUntradableValue(data?.untradableValue);
                setUntradableItems(data?.untradableItems);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getInventory();
    }, [token, page, sortBy, sort, nameSearch, tradable]);

    return (
        <div className="panel-wrapper">
            <div className="panel-row">
                <div className="panel-row-left">
                    <div className="panel-row-title">Inventory</div>
                    <div className="panel-row-group">
                        <div className="panel-row-subtitle">Total Items</div>
                        <div className="panel-row-bold-value">{(untradableItems + tradableItems).toLocaleString()}</div>
                    </div>
                    <div className="panel-row-group">
                        <div className="panel-row-subtitle">Total Value</div>
                        <div className="panel-row-bold-value">${((tradableValue + untradableValue) / 100).toLocaleString()}</div>
                    </div>
                    <div className="panel-row-group">
                        <div className="panel-row-red-background">
                            <Untradable />
                            {untradableItems.toLocaleString()}
                        </div>
                        <div className="panel-row-green-background">
                            <Tradable />
                            {tradableItems.toLocaleString()}
                        </div>
                    </div>
                </div>
                <div className="panel-row-right">
                    <div className="panel-section-button" onClick={getInventory}>
                        Refresh
                    </div>
                    <div className="panel-row-group">
                        <div
                            className="panel-section-button"
                            onClick={() => {
                                if (page > 1) setPage(page - 1);
                            }}
                        >
                            <LeftArrow />
                        </div>
                        <div
                            className="panel-section-button"
                            onClick={() => {
                                if (page < Math.ceil((tradableItems + untradableItems) / pageSize)) setPage(page + 1);
                            }}
                        >
                            <RightArrow />
                        </div>
                        Page {page} of {Math.ceil((tradableItems + untradableItems) / pageSize)}
                    </div>
                </div>
            </div>
            <div className="panel-row">
                <div className="panel-row-left">
                    <div className="panel-row-search">
                        <PanelRowSearch />
                        <input className="panel-row-search-input" placeholder="Search for item..." onBlur={(event) => setNameSearch(event.target.value)} />
                    </div>
                </div>
                <div className="panel-row-right">
                    <div className="panel-row-group">
                        Show:
                        <select className="panel-row-select" onChange={(e) => setTradable(e.target.value)}>
                            <option selected="selected" value={"all"}>
                                All Items
                            </option>
                            <option value={"true"}>Tradable</option>
                            <option value={"false"}>Untradable</option>
                        </select>
                    </div>
                    <div className="panel-row-group">
                        Sort:
                        <div
                            className="panel-section-button"
                            onClick={() => {
                                if (sort === "desc") setSort("asc");
                                else setSort("desc");
                            }}
                        >
                            <PanelRowSort />
                            {sort === "desc" ? "Price Desc" : "Price Asc"}
                        </div>
                    </div>
                    <div className="panel-row-group"></div>
                </div>
            </div>
            <div className="inventory">
                {isLoading ? (
                    <>
                        {Array.from({ length: pageSize }, (_, index) => (
                            <ItemCard loading={true} />
                        ))}
                    </>
                ) : (
                    <>
                        {inventory.map((item) => {
                            return (
                                <ItemCard
                                    loading={false}
                                    siteImages={siteImages}
                                    assetId={item?.assetId}
                                    iconUrl={item?.skin?.iconUrl}
                                    name={item?.skin?.name}
                                    phase={item?.skin?.phase}
                                    tradable={item?.tradable}
                                    tradableDate={item?.tradableDate}
                                    price={item?.skin?.fmv}
                                    purchasePrice={item?.transaction?.purchasePrice}
                                    listings={item?.listings}
                                />
                            );
                        })}
                    </>
                )}
            </div>
        </div>
    );
}

export default Inventory;
