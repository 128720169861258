function Untradable() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16" fill="none">
            <path
                d="M10.5276 5.57405V4.07776C10.5276 3.08566 10.1335 2.13419 9.43201 1.43267C8.73049 0.731147 7.77902 0.337036 6.78692 0.337036C5.79482 0.337036 4.84335 0.731147 4.14183 1.43267C3.4403 2.13419 3.04619 3.08566 3.04619 4.07776V5.57405C2.45093 5.57405 1.88005 5.81052 1.45914 6.23143C1.03822 6.65235 0.801758 7.22323 0.801758 7.81849V13.0555C0.801758 13.6508 1.03822 14.2216 1.45914 14.6426C1.88005 15.0635 2.45093 15.2999 3.04619 15.2999H10.5276C11.1229 15.2999 11.6938 15.0635 12.1147 14.6426C12.5356 14.2216 12.7721 13.6508 12.7721 13.0555V7.81849C12.7721 7.22323 12.5356 6.65235 12.1147 6.23143C11.6938 5.81052 11.1229 5.57405 10.5276 5.57405ZM4.54248 4.07776C4.54248 3.4825 4.77895 2.91162 5.19986 2.49071C5.62078 2.06979 6.19166 1.83333 6.78692 1.83333C7.38218 1.83333 7.95306 2.06979 8.37397 2.49071C8.79489 2.91162 9.03135 3.4825 9.03135 4.07776V5.57405H4.54248V4.07776ZM11.2758 13.0555C11.2758 13.2539 11.197 13.4442 11.0567 13.5845C10.9164 13.7248 10.7261 13.8036 10.5276 13.8036H3.04619C2.84777 13.8036 2.65748 13.7248 2.51717 13.5845C2.37687 13.4442 2.29805 13.2539 2.29805 13.0555V7.81849C2.29805 7.62007 2.37687 7.42977 2.51717 7.28947C2.65748 7.14917 2.84777 7.07034 3.04619 7.07034H10.5276C10.7261 7.07034 10.9164 7.14917 11.0567 7.28947C11.197 7.42977 11.2758 7.62007 11.2758 7.81849V13.0555Z"
                fill="#EF3737"
            />
        </svg>
    );
}

function Tradable() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16" fill="none">
            <path
                d="M10.1246 5.57418H4.13835V4.07763C4.13726 3.63329 4.26806 3.19863 4.51418 2.82868C4.7603 2.45874 5.11068 2.17016 5.52093 1.99948C5.93118 1.82881 6.38286 1.78373 6.81875 1.86995C7.25464 1.95616 7.65514 2.1698 7.96953 2.4838C8.25084 2.77119 8.45206 3.12719 8.55318 3.51642C8.57775 3.61174 8.62085 3.70128 8.68002 3.77994C8.73919 3.8586 8.81328 3.92484 8.89805 3.97487C8.98282 4.02489 9.07662 4.05774 9.17408 4.07152C9.27154 4.0853 9.37076 4.07974 9.46608 4.05518C9.5614 4.03061 9.65094 3.98751 9.7296 3.92834C9.80826 3.86917 9.8745 3.79508 9.92453 3.71031C9.97456 3.62554 10.0074 3.53175 10.0212 3.43428C10.035 3.33682 10.0294 3.2376 10.0048 3.14228C9.83437 2.49487 9.49627 1.90384 9.0246 1.42873C8.50094 0.906706 7.83434 0.551552 7.10898 0.408112C6.38362 0.264673 5.63201 0.339381 4.94908 0.622802C4.26615 0.906224 3.68251 1.38565 3.27186 2.00054C2.8612 2.61543 2.64196 3.33822 2.6418 4.07763V5.57418C2.04644 5.57418 1.47545 5.81069 1.05447 6.23167C0.633481 6.65266 0.396973 7.22364 0.396973 7.81901V13.0569C0.396973 13.6523 0.633481 14.2233 1.05447 14.6443C1.47545 15.0653 2.04644 15.3018 2.6418 15.3018H10.1246C10.7199 15.3018 11.2909 15.0653 11.7119 14.6443C12.1329 14.2233 12.3694 13.6523 12.3694 13.0569V7.81901C12.3694 7.22364 12.1329 6.65266 11.7119 6.23167C11.2909 5.81069 10.7199 5.57418 10.1246 5.57418ZM10.8728 13.0569C10.8728 13.2554 10.794 13.4457 10.6537 13.5861C10.5133 13.7264 10.323 13.8052 10.1246 13.8052H2.6418C2.44335 13.8052 2.25302 13.7264 2.11269 13.5861C1.97236 13.4457 1.89353 13.2554 1.89353 13.0569V7.81901C1.89353 7.62055 1.97236 7.43023 2.11269 7.2899C2.25302 7.14957 2.44335 7.07073 2.6418 7.07073H10.1246C10.323 7.07073 10.5133 7.14957 10.6537 7.2899C10.794 7.43023 10.8728 7.62055 10.8728 7.81901V13.0569Z"
                fill="#0ECB43"
            />
        </svg>
    );
}

function UntradableSmall() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 11" fill="none">
            <path
                d="M7.24142 3.61807V2.60031C7.24142 1.92549 6.97335 1.27831 6.49618 0.801146C6.01901 0.323978 5.37184 0.0559082 4.69702 0.0559082C4.0222 0.0559082 3.37502 0.323978 2.89786 0.801146C2.42069 1.27831 2.15262 1.92549 2.15262 2.60031V3.61807C1.74773 3.61807 1.35942 3.77891 1.07312 4.06521C0.786819 4.35151 0.625977 4.73982 0.625977 5.14471V8.70688C0.625977 9.11177 0.786819 9.50007 1.07312 9.78637C1.35942 10.0727 1.74773 10.2335 2.15262 10.2335H7.24142C7.64631 10.2335 8.03462 10.0727 8.32092 9.78637C8.60722 9.50007 8.76806 9.11177 8.76806 8.70688V5.14471C8.76806 4.73982 8.60722 4.35151 8.32092 4.06521C8.03462 3.77891 7.64631 3.61807 7.24142 3.61807ZM3.17038 2.60031C3.17038 2.19542 3.33122 1.80711 3.61752 1.52081C3.90382 1.23451 4.29213 1.07367 4.69702 1.07367C5.10191 1.07367 5.49022 1.23451 5.77652 1.52081C6.06282 1.80711 6.22366 2.19542 6.22366 2.60031V3.61807H3.17038V2.60031ZM7.7503 8.70688C7.7503 8.84184 7.69669 8.97128 7.60125 9.06671C7.50582 9.16214 7.37638 9.21576 7.24142 9.21576H2.15262C2.01765 9.21576 1.88822 9.16214 1.79278 9.06671C1.69735 8.97128 1.64374 8.84184 1.64374 8.70688V5.14471C1.64374 5.00975 1.69735 4.88031 1.79278 4.78488C1.88822 4.68945 2.01765 4.63583 2.15262 4.63583H7.24142C7.37638 4.63583 7.50582 4.68945 7.60125 4.78488C7.69669 4.88031 7.7503 5.00975 7.7503 5.14471V8.70688Z"
                fill="#EF3737"
            />
        </svg>
    );
}

function TradableSmall() {
    return (
        <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.24136 3.84417H3.1696V2.82623C3.16886 2.524 3.25783 2.22834 3.42524 1.97671C3.59265 1.72508 3.83097 1.52879 4.11002 1.4127C4.38907 1.29661 4.69629 1.26595 4.99278 1.32459C5.28927 1.38323 5.56169 1.52855 5.77553 1.74213C5.96687 1.93761 6.10374 2.17975 6.17252 2.4445C6.18923 2.50934 6.21855 2.57025 6.2588 2.62375C6.29905 2.67725 6.34944 2.72231 6.4071 2.75634C6.46476 2.79036 6.52856 2.8127 6.59485 2.82208C6.66114 2.83145 6.72863 2.82767 6.79347 2.81096C6.8583 2.79425 6.91921 2.76494 6.97271 2.72469C7.02622 2.68444 7.07127 2.63405 7.1053 2.57639C7.13933 2.51873 7.16166 2.45493 7.17104 2.38864C7.18041 2.32234 7.17664 2.25485 7.15993 2.19002C7.04397 1.74966 6.814 1.34764 6.49318 1.02448C6.13699 0.669405 5.68358 0.427832 5.19019 0.330267C4.69681 0.232701 4.18558 0.283516 3.72105 0.476297C3.25653 0.669077 2.85954 0.995177 2.58022 1.41342C2.3009 1.83166 2.15177 2.32329 2.15166 2.82623V3.84417C1.7467 3.84417 1.35833 4.00504 1.07198 4.29139C0.785626 4.57774 0.624756 4.96612 0.624756 5.37108V8.93387C0.624756 9.33883 0.785626 9.7272 1.07198 10.0136C1.35833 10.2999 1.7467 10.4608 2.15166 10.4608H7.24136C7.64632 10.4608 8.0347 10.2999 8.32105 10.0136C8.6074 9.7272 8.76827 9.33883 8.76827 8.93387V5.37108C8.76827 4.96612 8.6074 4.57774 8.32105 4.29139C8.0347 4.00504 7.64632 3.84417 7.24136 3.84417ZM7.75033 8.93387C7.75033 9.06885 7.69671 9.19831 7.60126 9.29376C7.50581 9.38921 7.37635 9.44284 7.24136 9.44284H2.15166C2.01668 9.44284 1.88722 9.38921 1.79177 9.29376C1.69632 9.19831 1.6427 9.06885 1.6427 8.93387V5.37108C1.6427 5.23609 1.69632 5.10663 1.79177 5.01118C1.88722 4.91573 2.01668 4.86211 2.15166 4.86211H7.24136C7.37635 4.86211 7.50581 4.91573 7.60126 5.01118C7.69671 5.10663 7.75033 5.23609 7.75033 5.37108V8.93387Z"
                fill="#0ECB43"
            />
        </svg>
    );
}

export { Untradable, Tradable, UntradableSmall, TradableSmall };
