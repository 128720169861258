import React, { useState, useEffect } from "react";

import KeyStatistics from "../components/KeyStatistics";
import OverviewChart from "../components/OverviewChart";
import AssetsChart from "../components/AssetsChart";
const BASE_URL = "http://localhost:3002";

function Home() {
    return (
        <div className="panel-wrapper">
            <KeyStatistics />
            <OverviewChart />
            <AssetsChart />
        </div>
    );
}

export default Home;
