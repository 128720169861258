import React, { useState, useEffect } from "react";

import BotStatus from "../components/BotStatus";

const BASE_URL = "http://localhost:3002";

function Home() {
    return (
        <div className="panel-wrapper">
            <BotStatus />
        </div>
    );
}

export default Home;
