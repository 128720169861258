import React, { useState, useEffect } from "react";

import StatBox from "../components/StatBox";

function Accounts() {
    return (
        <div className="panel-wrapper">
            <div className="panel-section">
                <div className="panel-section-selection">
                    <div className="panel-section-selection-left">
                        <div className="panel-section-selection-header-large">Accounts Overview</div>
                    </div>
                    <div className="panel-section-selection-right">
                        <div className="panel-section-button">Refresh</div>
                    </div>
                </div>
                <div className="stat-row">
                    {StatBox()}
                    {StatBox()}
                    {StatBox()}
                    {StatBox()}
                </div>
            </div>
        </div>
    );
}

export default Accounts;
