import React, { useState, useEffect } from "react";
import { Cookies } from "react-cookie";

import { SoldSvg, PurchasedSvg, ProfitSvg, PercentProfitSvg } from "../svgs/Statistics";
import { rangeToTime } from "../helpers/time";
import { toDollarFormat } from "../helpers/item";
import StatBox from "./StatBox";

function KeyStatistics() {
    const [statisticsRange, setStatisticsRange] = useState("1d");
    const [inventoryValue, setInventoryValue] = useState(0);
    const [soldTotal, setSoldTotal] = useState(null);
    const [purchasedTotal, setPurchasedTotal] = useState(null);
    const [profitTotal, setProfitTotal] = useState(null);
    const [profitPercentage, setProfitPercentage] = useState(null);
    const [balances, setBalances] = useState(0);

    const cookies = new Cookies();
    const token = cookies.get("token");

    const [isLoading, setIsLoading] = useState(false);

    const getStatistics = async () => {
        if (isLoading) {
            return; // Return early if the previous call is still running
        }

        setIsLoading(true);
        setSoldTotal(null);
        setPurchasedTotal(null);
        setProfitTotal(null);
        setProfitPercentage(null);
        try {
            const url = process.env.REACT_APP_TRANSACTIONS_API_BASE_URL + "/v1/history/stats";
            //build query string based on statisticsRange
            let query = `?after=${rangeToTime(statisticsRange)}}`;
            const res = await fetch(url + query, {
                method: "GET",
                headers: {
                    "x-api-key": cookies.get("token"),
                },
            });
            const reqData = await res.json();
            const { success, data } = reqData;
            if (success) {
                setSoldTotal(data?.totalSold);
                setPurchasedTotal(data?.totalPurchased);
                setProfitTotal(data?.totalProfit);
                setProfitPercentage(data?.averageProfitPercent);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };
    const getInventoryValue = async () => {
        try {
            const urlOne = process.env.REACT_APP_TRANSACTIONS_API_BASE_URL + "/v1/inventory/updateStats";
            const resOne = await fetch(urlOne, {
                method: "GET",
                headers: {
                    "x-api-key": cookies.get("token"),
                },
            });
            const url = process.env.REACT_APP_TRANSACTIONS_API_BASE_URL + "/v1/inventory/stats";
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "x-api-key": cookies.get("token"),
                },
            });
        } catch (error) {}
    };

    const getBalances = async () => {
        try {
            const url = process.env.REACT_APP_TRANSACTIONS_API_BASE_URL + "/v1/site/stats";
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "x-api-key": cookies.get("token"),
                },
            });
            const reqData = await res.json();
            const { success, data } = reqData;
            if (success) {
                setBalances(data?.totalBalance);
                setInventoryValue(data?.totalInventoryValue);
            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        getStatistics();
    }, [token, statisticsRange]);
    useEffect(() => {
        getInventoryValue();
        getBalances();
    }, [token]);
    const refreshAll = async () => {
        getStatistics();
        getInventoryValue();
        getBalances();
    };

    return (
        <div className="panel-section">
            <div className="panel-section-header">Key Statistics</div>
            <div className="panel-section-selection">
                <div className="panel-section-selection-left">
                    <div className="panel-section-selection-header">Time Period:</div>
                    <div className={`panel-section-button ${statisticsRange === "1d" ? "active" : ""}`} onClick={() => setStatisticsRange("1d")}>
                        1d
                    </div>
                    <div className={`panel-section-button ${statisticsRange === "7d" ? "active" : ""}`} onClick={() => setStatisticsRange("7d")}>
                        7d
                    </div>
                    <div className={`panel-section-button ${statisticsRange === "30d" ? "active" : ""}`} onClick={() => setStatisticsRange("30d")}>
                        30d
                    </div>
                    <div className={`panel-section-button ${statisticsRange === "Mtd" ? "active" : ""}`} onClick={() => setStatisticsRange("Mtd")}>
                        Mtd
                    </div>
                    <div className={`panel-section-button ${statisticsRange === "All-time" ? "active" : ""}`} onClick={() => setStatisticsRange("All-time")}>
                        All-Time
                    </div>
                </div>
                <div className="panel-section-selection-right">
                    <div className="panel-section-group">
                        <div className="panel-section-selection-header">Inventory Value</div>
                        <div className="panel-section-selection-number">{toDollarFormat(inventoryValue)}</div>
                    </div>
                    <div className="panel-section-group">
                        <div className="panel-section-selection-header">Balances</div>
                        <div className="panel-section-selection-number">{toDollarFormat(balances)}</div>
                    </div>
                    <div className="panel-section-button" onClick={refreshAll}>
                        Refresh
                    </div>
                </div>
            </div>
            <div className="stat-row">
                {StatBox(<SoldSvg />, soldTotal, "Sold", statisticsRange)}
                {StatBox("icon", purchasedTotal, "Purchased", statisticsRange)}
                {StatBox("icon", profitTotal, "Profit", statisticsRange)}
                {StatBox("icon", profitPercentage, "Profit Percentage", statisticsRange, "percent")}
            </div>
        </div>
    );
}

export default KeyStatistics;
