import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Cookies } from "react-cookie";
import { extractCondition, toDollarFormat, getImageUrl } from "../helpers/item";
import PriceChart from "../components/PriceChart";
import ItemCard from "../components/ItemCard";
import { formatDate, formatShortDate } from "../helpers/time";

function Item({ siteImages }) {
    let { name, phase } = useParams();
    if (phase === "null") phase = null;
    const [isLoadingItem, setIsLoadingItem] = useState(false);
    const [item, setItem] = useState(null);
    const [similarItems, setSimilarItems] = useState([]);
    const [isLoadingRecentTransactions, setIsLoadingRecentTransactions] = useState(false);
    const [recentTransactions, setRecentTransactions] = useState([]);

    const cookies = new Cookies();
    const token = cookies.get("token");

    const getRecentTrasnactions = async () => {
        try {
            if (isLoadingRecentTransactions) return;
            setIsLoadingRecentTransactions(true);
            const url = process.env.REACT_APP_TRANSACTIONS_API_BASE_URL + "/v1/history/";
            let query = `?name=${name}&sold=true&pageSize=5&page=1&sortBy=saleTime&sort=desc`;
            if (phase && phase !== "null") query += `&phase=${phase}`;
            const res = await fetch(url + query, {
                method: "GET",
                headers: {
                    "x-api-key": token,
                },
            });
            const reqData = await res.json();
            const { success, data } = reqData;
            if (success) {
                setRecentTransactions(data?.transactions);
            }
        } catch (error) {
        } finally {
            setIsLoadingRecentTransactions(false);
        }
    };

    const getSimilarItems = async () => {
        try {
            const url = process.env.REACT_APP_TRANSACTIONS_API_BASE_URL + "/v1/inventory";
            let query = `?name=${name}&pageSize=6&page=1`;
            const res = await fetch(url + query, {
                method: "GET",
                headers: {
                    "x-api-key": token,
                },
            });
            const reqData = await res.json();
            const { success, data } = reqData;
            if (success) {
                setSimilarItems(data?.inventory);
            }
        } catch (error) {}
    };

    const getItem = async () => {
        try {
            if (isLoadingItem) return;
            setIsLoadingItem(true);
            let url = `${process.env.REACT_APP_PRICING_API_BASE_URL}/v1/prices/item/${name}`;
            if (phase && phase !== "null") url += `/${phase}`;
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "x-api-key": token,
                },
            });
            const reqData = await res.json();
            const { success, data } = reqData;
            if (success) {
                setItem(data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingItem(false);
        }
    };

    useEffect(() => {
        getSimilarItems();
        getItem();
        getRecentTrasnactions();
    }, [token, name, phase]);

    let [parsedName, parsedNameShort, parsedCondition, parsedConditionShort] = extractCondition(name);

    return (
        <>
            <div className="panel-wrapper">
                <div className="item-header">
                    <div className="item-header-left">
                        <div className="item-header-name">
                            {parsedName}{" "}
                            <div className="item-header-condition">
                                {parsedCondition}
                                {phase ? " · " : null}
                                {phase}
                            </div>
                        </div>
                        <div className="item-header-image-container">
                            <img className="item-header-image" src={getImageUrl(item?.iconUrl)} />
                        </div>
                        <div className="item-header-stats">
                            <div className="item-header-stat">
                                <div className="item-header-stat-title">Worth</div>
                                <div className="item-header-stat-value">{toDollarFormat(item?.fmv)}</div>
                            </div>
                            <div className="item-header-stat">
                                <div className="item-header-stat-title">Buy Price</div>
                                <div className="item-header-stat-value">{toDollarFormat(item?.buyPrice)}</div>
                            </div>
                            <div className="item-header-stat">
                                <div className="item-header-stat-title">Sell Price</div>
                                <div className="item-header-stat-value">{toDollarFormat(item?.sellPrice)}</div>
                            </div>
                        </div>
                    </div>
                    <div className="item-header-right">
                        <div className="item-header-listed">
                            <div className="item-header-listed-header">Recent Transactions</div>
                            {isLoadingRecentTransactions ? (
                                <>
                                    {" "}
                                    <div className="item-header-listed-site"></div>
                                    <div className="item-header-listed-site"></div>
                                    <div className="item-header-listed-site"></div>
                                    <div className="item-header-listed-site"></div>
                                    <div className="item-header-listed-site"></div>
                                </>
                            ) : (
                                <>
                                    {recentTransactions.map((transaction) => {
                                        return (
                                            <div className="item-header-listed-site">
                                                <div className="item-header-listed-site-left">
                                                    <img className="item-header-listed-site-image" src={getImageUrl(siteImages[transaction.purchaseLocation])} />
                                                    <div className="inventory-item-bottom-listed-price">{toDollarFormat(transaction?.purchasePrice)}</div>
                                                    {formatShortDate(transaction.purchaseTime)}
                                                </div>
                                                <div className="item-header-listed-site-right">
                                                    <img className="item-header-listed-site-image" src={getImageUrl(siteImages[transaction.saleLocation])} />
                                                    <div className="inventory-item-bottom-listed-price">{toDollarFormat(transaction.salePrice)}</div>
                                                    {formatShortDate(transaction.saleTime)}
                                                </div>
                                            </div>
                                        );
                                    })}{" "}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <PriceChart name={name} phase={phase} />
                <div className="panel-section">
                    <div className="panel-section-selection">
                        <div className="panel-section-selection-left">
                            <div className="panel-section-selection-header">Inventory of this Item</div>
                        </div>
                        <div className="panel-section-selection-right">
                            <div className="panel-section-button">View all</div>
                        </div>
                    </div>
                    <div className="inventory">
                        {isLoadingItem ? (
                            <>
                                {Array.from({ length: 6 }, (_, index) => (
                                    <ItemCard loading={true} />
                                ))}
                            </>
                        ) : (
                            <>
                                {similarItems.map((item) => {
                                    return (
                                        <ItemCard
                                            loading={false}
                                            siteImages={siteImages}
                                            assetId={item?.assetId}
                                            iconUrl={item?.skin?.iconUrl}
                                            name={item?.skin?.name}
                                            phase={item?.skin?.phase}
                                            tradable={item?.tradable}
                                            tradableDate={item?.tradableDate}
                                            price={item?.skin?.fmv}
                                            purchasePrice={item?.transaction?.purchasePrice}
                                            listings={item?.listings}
                                        />
                                    );
                                })}
                            </>
                        )}
                    </div>
                </div>
            </div>
            ))}
        </>
    );
}

export default Item;
