function PanelRowSearch() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path
                d="M18.7655 17.9156L16.4417 15.5986C17.1914 14.6434 17.5982 13.4638 17.5967 12.2495C17.5967 11.1681 17.2761 10.111 16.6752 9.21179C16.0744 8.31262 15.2205 7.6118 14.2214 7.19795C13.2223 6.78411 12.1229 6.67583 11.0622 6.8868C10.0016 7.09778 9.0273 7.61854 8.26262 8.38322C7.49793 9.14791 6.97717 10.1222 6.7662 11.1828C6.55522 12.2435 6.6635 13.3429 7.07735 14.342C7.49119 15.3411 8.19201 16.195 9.09119 16.7959C9.99036 17.3967 11.0475 17.7173 12.1289 17.7173C13.3432 17.7189 14.5228 17.312 15.478 16.5623L17.7949 18.8861C17.8585 18.9501 17.9341 19.001 18.0174 19.0357C18.1007 19.0704 18.19 19.0883 18.2802 19.0883C18.3704 19.0883 18.4598 19.0704 18.5431 19.0357C18.6264 19.001 18.7019 18.9501 18.7655 18.8861C18.8295 18.8225 18.8804 18.747 18.9151 18.6637C18.9498 18.5804 18.9677 18.491 18.9677 18.4008C18.9677 18.3106 18.9498 18.2213 18.9151 18.138C18.8804 18.0547 18.8295 17.9791 18.7655 17.9156ZM8.02809 12.2495C8.02809 11.4385 8.2686 10.6456 8.7192 9.97123C9.16981 9.29685 9.81028 8.77123 10.5596 8.46085C11.3089 8.15047 12.1335 8.06926 12.929 8.22749C13.7245 8.38572 14.4552 8.77629 15.0287 9.3498C15.6022 9.92332 15.9928 10.654 16.151 11.4495C16.3092 12.245 16.228 13.0695 15.9176 13.8189C15.6072 14.5682 15.0816 15.2087 14.4072 15.6593C13.7329 16.1099 12.94 16.3504 12.1289 16.3504C11.0413 16.3504 9.99826 15.9183 9.2292 15.1493C8.46014 14.3802 8.02809 13.3372 8.02809 12.2495Z"
                fill="#7F7F7F"
            />
        </svg>
    );
}

function PanelRowSort() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.7084 10.5673H6.29206C6.14843 10.5673 6.01068 10.4998 5.90911 10.3795C5.80755 10.2592 5.75049 10.0961 5.75049 9.926C5.75049 9.75591 5.80755 9.59278 5.90911 9.47251C6.01068 9.35224 6.14843 9.28467 6.29206 9.28467H17.7084C17.8521 9.28467 17.9898 9.35224 18.0914 9.47251C18.1929 9.59278 18.25 9.75591 18.25 9.926C18.25 10.0961 18.1929 10.2592 18.0914 10.3795C17.9898 10.4998 17.8521 10.5673 17.7084 10.5673ZM13.717 14.4153H6.29206C6.14843 14.4153 6.01068 14.3478 5.90911 14.2275C5.80755 14.1072 5.75049 13.9441 5.75049 13.774C5.75049 13.6039 5.80755 13.4408 5.90911 13.3205C6.01068 13.2002 6.14843 13.1327 6.29206 13.1327H13.717C13.8607 13.1327 13.9984 13.2002 14.1 13.3205C14.2015 13.4408 14.2586 13.6039 14.2586 13.774C14.2586 13.9441 14.2015 14.1072 14.1 14.2275C13.9984 14.3478 13.8607 14.4153 13.717 14.4153ZM6.29206 18.2634H9.69314C9.83678 18.2634 9.97453 18.1958 10.0761 18.0755C10.1777 17.9552 10.2347 17.7921 10.2347 17.622C10.2347 17.4519 10.1777 17.2888 10.0761 17.1685C9.97453 17.0483 9.83678 16.9807 9.69314 16.9807H6.29206C6.14843 16.9807 6.01068 17.0483 5.90911 17.1685C5.80755 17.2888 5.75049 17.4519 5.75049 17.622C5.75049 17.7921 5.80755 17.9552 5.90911 18.0755C6.01068 18.1958 6.14843 18.2634 6.29206 18.2634Z"
                fill="#7F7F7F"
            />
        </svg>
    );
}

export { PanelRowSearch, PanelRowSort };
