function LogoSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63" fill="none">
            <path
                d="M38.4615 20.1942L38.4615 2.56742M38.4615 2.56742L20.8347 2.56742M38.4615 2.56742L3.20791 37.821M25.2414 42.2277L25.2414 59.8544M25.2414 59.8544L42.8682 59.8544M25.2414 59.8544L60.4949 24.6009"
                stroke="#D2E214"
                stroke-width="4.67401"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}

export default LogoSvg;
