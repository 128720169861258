function LowestPriceSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <g clip-path="url(#clip0_952_18536)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.0643 2.38586L3.5943 2.91586L5.8443 5.16586C5.98099 5.30725 6.05667 5.49667 6.05506 5.69332C6.05344 5.88997 5.97465 6.07811 5.83566 6.21724C5.69667 6.35636 5.5086 6.43532 5.31195 6.43712C5.1153 6.43893 4.92582 6.36342 4.7843 6.22686L3.8143 5.25686V13.6369C3.8143 13.8358 3.73528 14.0265 3.59463 14.1672C3.45398 14.3078 3.26321 14.3869 3.0643 14.3869C2.86539 14.3869 2.67462 14.3078 2.53397 14.1672C2.39332 14.0265 2.3143 13.8358 2.3143 13.6369V5.25586L1.3443 6.22586C1.20212 6.35834 1.01408 6.43047 0.819777 6.42704C0.625476 6.42361 0.440091 6.3449 0.302678 6.20749C0.165265 6.07007 0.0865529 5.88469 0.0831247 5.69039C0.0796964 5.49609 0.15182 5.30804 0.2843 5.16586L2.5343 2.91586L3.0643 2.38586ZM8.0643 7.13586C8.0643 7.54986 8.4003 7.88586 8.8143 7.88586H10.3143C10.5132 7.88586 10.704 7.80685 10.8446 7.66619C10.9853 7.52554 11.0643 7.33478 11.0643 7.13586C11.0643 6.93695 10.9853 6.74619 10.8446 6.60553C10.704 6.46488 10.5132 6.38586 10.3143 6.38586H8.8143C8.61539 6.38586 8.42462 6.46488 8.28397 6.60553C8.14332 6.74619 8.0643 6.93695 8.0643 7.13586ZM8.8143 11.1359C8.61539 11.1359 8.42462 11.0568 8.28397 10.9162C8.14332 10.7755 8.0643 10.5848 8.0643 10.3859C8.0643 10.187 8.14332 9.99619 8.28397 9.85553C8.42462 9.71488 8.61539 9.63586 8.8143 9.63586H13.3143C13.5132 9.63586 13.704 9.71488 13.8446 9.85553C13.9853 9.99619 14.0643 10.187 14.0643 10.3859C14.0643 10.5848 13.9853 10.7755 13.8446 10.9162C13.704 11.0568 13.5132 11.1359 13.3143 11.1359H8.8143ZM8.8143 14.3859C8.61539 14.3859 8.42462 14.3068 8.28397 14.1662C8.14332 14.0255 8.0643 13.8348 8.0643 13.6359C8.0643 13.437 8.14332 13.2462 8.28397 13.1055C8.42462 12.9649 8.61539 12.8859 8.8143 12.8859H15.3143C15.5132 12.8859 15.704 12.9649 15.8446 13.1055C15.9853 13.2462 16.0643 13.437 16.0643 13.6359C16.0643 13.8348 15.9853 14.0255 15.8446 14.1662C15.704 14.3068 15.5132 14.3859 15.3143 14.3859H8.8143Z"
                    fill="#D2E214"
                />
            </g>
            <defs>
                <clipPath id="clip0_952_18536">
                    <rect width="16" height="16" fill="white" transform="translate(0.0644531 0.385864)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export { LowestPriceSvg };
