import React, { useState, useEffect } from "react";
import { Route, Switch, useRouteMatch, NavLink } from "react-router-dom";
import { Cookies } from "react-cookie";

import { HomeSvg, TransactionsSvg, StatusSvg, BalancesSvg, AccountsSvg, InventorySvg, LogsSvg, SearchSvg } from "../svgs/Nav";
import Transactions from "./Transactions";
import Inventory from "./Inventory";
import Home from "./Home";
import Accounts from "./Accounts";
import Status from "./Status";
import LiveFeed from "../components/LiveFeed";
import InventoryItem from "../pages/InventoryItem";
import Item from "../pages/Item";

const Panel = () => {
    const [name, setName] = useState("John Doe");
    const [siteImages, setSiteImages] = useState({});

    const cookies = new Cookies();
    const token = cookies.get("token");
    let { path } = useRouteMatch();
    const logout = async () => {
        if (!token) return;
        const res = await fetch(process.env.REACT_APP_AUTH_API_BASE_URL + "/v1/auth/logout", {
            method: "GET",
            headers: {
                token: cookies.get("token"),
            },
        });
        const data = await res.json();
        const { success } = data;
        if (success) {
            cookies.remove("token");
            window.location.href = "/login";
        }
    };

    const getSiteImages = async () => {
        try {
            const url = process.env.REACT_APP_TRANSACTIONS_API_BASE_URL + "/v1/site/images";
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "x-api-key": cookies.get("token"),
                },
            });
            const reqData = await res.json();
            const { success, data } = reqData;
            if (success) {
                const siteImages = {};
                data?.forEach((image) => {
                    siteImages[image.name] = image.icon;
                });
                setSiteImages(siteImages);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getMe = async () => {
        try {
            if (!token) return;
            const res = await fetch(process.env.REACT_APP_AUTH_API_BASE_URL + "/v1/auth/me", {
                method: "GET",
                headers: {
                    token: cookies.get("token"),
                },
            });
            const info = await res.json();
            const { success, data } = info;
            if (success) {
                setName(data?.email);
            }
        } catch (error) {}
    };
    useEffect(() => {
        getMe();
        getSiteImages();
    }, [token]);

    return (
        <div className="panel">
            <div className="panel-header">
                <img className="background-image" src="/background.png" />
                <div className="nav-bar">
                    <div className="nav-bar-logo">
                        <span className="logo-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                <path d="M14.9839 7.84028V1.04669M14.9839 1.04669H8.19037M14.9839 1.04669L1.39678 14.6339M9.88876 16.3323V23.1258M9.88876 23.1258H16.6823M9.88876 23.1258L23.4759 9.53867" stroke="#D2E214" stroke-width="1.80142" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>

                        <span className="logo-text">
                            LES<span className="logo-white-text">Trade</span>
                        </span>
                    </div>

                    <div className="nav-bar-nav">
                        {tab("Home", <HomeSvg />)}
                        {tab("Transactions", <TransactionsSvg />)}
                        {tab("Status", <StatusSvg />)}
                        {tab("Accounts", <AccountsSvg />)}
                        {tab("Inventory", <InventorySvg />)}
                        {tabRedirect("Logs", <LogsSvg />, "https://app.logz.io/#/dashboard/home")}
                    </div>
                    <div className="nav-bar-user">
                        <div className="nav-bar-user-info">
                            {/* <img className="nav-bar-user-info-icon" src="/profile.png" /> */}
                            <div className="nav-bar-user-info-name">{name}</div>
                            <div className="nav-bar-user-split"></div>
                            <div className="nav-bar-user-logout" onClick={logout}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                                    <path
                                        d="M4.14583 15.9547C3.74479 15.9547 3.4016 15.812 3.11625 15.5267C2.8309 15.2413 2.68799 14.8979 2.6875 14.4964V4.28805C2.6875 3.887 2.83042 3.54381 3.11625 3.25846C3.40208 2.97311 3.74528 2.8302 4.14583 2.82971H9.25V4.28805H4.14583V14.4964H9.25V15.9547H4.14583ZM12.1667 13.038L11.1641 11.9808L13.0234 10.1214H7.0625V8.66305H13.0234L11.1641 6.80367L12.1667 5.74638L15.8125 9.39221L12.1667 13.038Z"
                                        fill="#D2E214"
                                    />
                                </svg>
                            </div>
                            "
                        </div>
                    </div>
                </div>
                <LiveFeed />
                <div className="nav-bar-line"></div>
            </div>
            <div className="panel-content">
                <Switch>
                    <Route path={`${path}/transactions`}>
                        <Transactions siteImages={siteImages} />
                    </Route>
                    <Route path={`${path}/status`} component={Status} />
                    <Route path={`${path}/home`} component={Home} />
                    <Route exact path={`${path}/inventory`}>
                        <Inventory siteImages={siteImages} />
                    </Route>
                    <Route path={`${path}/accounts`}>
                        <Accounts siteImages={siteImages} />
                    </Route>
                    <Route path={`${path}/inventory/:assetId`}>
                        <InventoryItem siteImages={siteImages} />
                    </Route>
                    <Route path={`${path}/item/:name/:phase`}>
                        <Item siteImages={siteImages} />
                    </Route>
                </Switch>
            </div>
        </div>
    );
};

function tab(name, icon) {
    let path = `/panel/${name.toLowerCase()}`;
    return (
        <NavLink className="tab" to={path} activeClassName="active">
            <div className="tab-interior">
                <span className="tab-icon">{icon}</span>
                <span className="tab-text">{name}</span>
            </div>
        </NavLink>
    );
}

function tabRedirect(name, icon, link) {
    return (
        <a className="tab" href={link} target="_blank" activeClassName="active">
            <div className="tab-interior">
                <span className="tab-icon">{icon}</span>
                <span className="tab-text">{name}</span>
            </div>
        </a>
    );
}

export default Panel;
