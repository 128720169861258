import React, { useState, useEffect } from "react";
import { Cookies } from "react-cookie";
import { SoldSvg, PurchasedSvg, ProfitSvg, PercentProfitSvg } from "../svgs/Statistics";
import LogoSvg from "../svgs/Logo";

function BotStatus() {
    const [botsStatus, setBotsStatus] = useState([{}, {}, {}, {}, {}, {}, {}, {}, {}]);
    const [isLoading, setIsLoading] = useState(false);
    const cookies = new Cookies();
    const token = cookies.get("token");

    const setSiteStatus = async (buy, sell, siteName) => {
        try {
            const url = `${process.env.REACT_APP_TRANSACTIONS_API_BASE_URL}/v1/site/status`;
            const res = await fetch(url, {
                method: "POST",

                headers: {
                    "x-api-key": cookies.get("token"),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ buy, sell, siteName }),
            });
            const reqData = await res.json();
            const { success, data } = reqData;
            if (success) {
                // find bot in botStatus and update it
                const botIndex = botsStatus.findIndex((bot) => bot.name === siteName);
                const newBotsStatus = [...botsStatus];
                newBotsStatus[botIndex].status = data;
                setBotsStatus(newBotsStatus);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getSiteBots = async () => {
        try {
            if (isLoading) {
                return;
            }
            setIsLoading(true);
            setBotsStatus(botsStatus.map((bot) => ({})));
            const url = process.env.REACT_APP_TRANSACTIONS_API_BASE_URL + "/v1/site/bots";
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "x-api-key": cookies.get("token"),
                },
            });
            const reqData = await res.json();
            const { success, data } = reqData;
            if (success) {
                setBotsStatus(data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getSiteBots();
    }, [token]);

    return (
        <div className="panel-section">
            <div className="site-bots-container">
                {botsStatus.map((botData) => {
                    return BotStatusItem(botData, setSiteStatus);
                })}
            </div>
        </div>
    );
}

function BotStatusItem(botData, setSiteStatus) {
    const { name, icon, health, accounts, status, purchased, sold, balance } = botData;
    const buy = status?.buy;
    const sell = status?.sell;
    return (
        <div className="site-bot">
            <div className="site-bot-inner">
                {buy != null ? (
                    <div className="site-bot-content">
                        <div className="site-bot-header">
                            <div className="site-bot-header-left">
                                <img className="site-bot-header-icon" src={icon} />
                                <div className="site-bot-header-text-container">
                                    <div className="site-bot-header-text-title">{name}</div>
                                    <div className="site-bot-header-text-subtitle">
                                        Status: <span className={health ? "healthy" : "dead"}>{health ? "Healthy" : "Dead"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="site-bot-divider"></div>
                        <div className="site-bot-info">
                            <div className="site-bot-info-inner">
                                <div className="site-bot-info-inner-two">
                                    <div className="site-bot-info-inner-three">
                                        <div className="site-bot-info-toggle-text">Sell</div>
                                        <div className="site-bot-info-toggle">
                                            {" "}
                                            <label className="switch">
                                                <input type="checkbox" checked={sell} onClick={() => setSiteStatus(null, !sell, name)} readOnly />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="site-bot-info-inner-two">
                                    <div className="site-bot-info-inner-three">
                                        <div className="site-bot-info-toggle-text">Buy</div>
                                        <div className="site-bot-info-toggle">
                                            {" "}
                                            <label className="switch">
                                                <input type="checkbox" checked={buy} onClick={() => setSiteStatus(!buy, null, name)} readOnly />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="site-bot-info-inner-two">
                                    <div className="site-bot-info-inner-three">
                                        <div className="site-bot-info-stats-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                                                <path
                                                    d="M14.2783 7.42963C14.2278 7.48018 14.1679 7.52029 14.1019 7.54765C14.0359 7.57502 13.9651 7.5891 13.8937 7.5891C13.8222 7.5891 13.7514 7.57502 13.6854 7.54765C13.6194 7.52029 13.5595 7.48018 13.509 7.42963L9.54372 3.46371V14.1136C9.54372 14.2578 9.48643 14.3961 9.38446 14.4981C9.28249 14.6 9.14418 14.6573 8.99998 14.6573C8.85577 14.6573 8.71746 14.6 8.61549 14.4981C8.51352 14.3961 8.45623 14.2578 8.45623 14.1136V3.46371L4.491 7.42963C4.38897 7.53166 4.25059 7.58897 4.1063 7.58897C3.96201 7.58897 3.82363 7.53166 3.7216 7.42963C3.61957 7.3276 3.56226 7.18922 3.56226 7.04493C3.56226 6.90064 3.61957 6.76226 3.7216 6.66023L8.61528 1.76656C8.66578 1.716 8.72575 1.6759 8.79175 1.64853C8.85776 1.62117 8.92852 1.60709 8.99998 1.60709C9.07143 1.60709 9.14219 1.62117 9.2082 1.64853C9.27421 1.6759 9.33417 1.716 9.38467 1.76656L14.2783 6.66023C14.3289 6.71073 14.369 6.7707 14.3964 6.83671C14.4237 6.90272 14.4378 6.97347 14.4378 7.04493C14.4378 7.11639 14.4237 7.18714 14.3964 7.25315C14.369 7.31916 14.3289 7.37913 14.2783 7.42963Z"
                                                    fill="#D2E214"
                                                />
                                            </svg>
                                        </div>
                                        <div className="site-bot-info-stats-number">{(sold / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
                                    </div>
                                </div>
                                <div className="site-bot-info-inner-two">
                                    <div className="site-bot-info-inner-three">
                                        <div className="site-bot-info-stats-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
                                                <path
                                                    d="M3.7219 8.83517C3.77239 8.78462 3.83236 8.74451 3.89837 8.71715C3.96438 8.68978 4.03514 8.6757 4.10659 8.6757C4.17805 8.6757 4.24881 8.68978 4.31482 8.71715C4.38082 8.74451 4.44079 8.78462 4.49129 8.83517L8.45653 12.8011L8.45653 2.15123C8.45653 2.00702 8.51381 1.86872 8.61579 1.76675C8.71776 1.66477 8.85606 1.60749 9.00027 1.60749C9.14448 1.60749 9.28278 1.66477 9.38475 1.76675C9.48672 1.86872 9.54401 2.00702 9.54401 2.15123L9.54401 12.8011L13.5092 8.83517C13.6113 8.73315 13.7497 8.67583 13.8939 8.67583C14.0382 8.67583 14.1766 8.73315 14.2786 8.83517C14.3807 8.9372 14.438 9.07558 14.438 9.21987C14.438 9.36416 14.3807 9.50254 14.2786 9.60457L9.38497 14.4982C9.33447 14.5488 9.2745 14.5889 9.20849 14.6163C9.14248 14.6436 9.07172 14.6577 9.00027 14.6577C8.92881 14.6577 8.85806 14.6436 8.79205 14.6163C8.72604 14.5889 8.66607 14.5488 8.61557 14.4982L3.7219 9.60457C3.67134 9.55407 3.63123 9.4941 3.60387 9.42809C3.57651 9.36208 3.56242 9.29133 3.56242 9.21987C3.56242 9.14841 3.57651 9.07766 3.60387 9.01165C3.63123 8.94564 3.67134 8.88567 3.7219 8.83517Z"
                                                    fill="#D2E214"
                                                />
                                            </svg>
                                        </div>
                                        <div className="site-bot-info-stats-number">{(purchased / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="site-bot-accounts">
                            <div className="site-bot-accounts-header">
                                <div className="site-bot-accounts-header-left">
                                    <div className="site-bot-accounts-header-icon">
                                        <div className="site-bot-accounts-header-icon-inner">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
                                                <path
                                                    d="M10.9999 0.58429C12.4434 0.58429 13.8727 0.868596 15.2062 1.42098C16.5398 1.97336 17.7515 2.78299 18.7722 3.80366C19.7928 4.82433 20.6025 6.03603 21.1549 7.3696C21.7072 8.70316 21.9915 10.1325 21.9915 11.5759C21.9915 14.4911 20.8335 17.2868 18.7722 19.3482C16.7108 21.4095 13.9151 22.5675 10.9999 22.5675C5.94378 22.5675 1.712 19.1821 0.403999 14.5766L4.61379 16.3133C4.75861 17.0171 5.14156 17.6495 5.69814 18.104C6.25471 18.5584 6.95091 18.8072 7.66946 18.8084C9.38415 18.8084 10.7801 17.4125 10.7801 15.6978V15.5549L14.5172 12.8839H14.6052C16.8914 12.8839 18.749 11.0263 18.749 8.74007C18.749 6.45381 16.8914 4.59623 14.6052 4.59623C12.3189 4.59623 10.4503 6.45381 10.4503 8.74007V8.79503L7.84532 12.5981L7.66946 12.5871C7.02095 12.5871 6.41641 12.785 5.92179 13.1257L0.00830078 10.6966C0.48094 5.03589 5.20734 0.58429 10.9999 0.58429ZM6.91104 17.2586C7.79037 17.6213 8.8016 17.2146 9.16432 16.3353C9.52704 15.4559 9.10936 14.4557 8.25202 14.093L6.84509 13.5104C7.38368 13.3126 7.98822 13.3016 8.55978 13.5434C9.14234 13.7742 9.59299 14.2249 9.82382 14.8074C10.0656 15.379 10.0656 16.0165 9.82382 16.5881C9.35118 17.7752 7.95524 18.3467 6.76815 17.8521C6.21856 17.6213 5.80088 17.2036 5.57006 16.709L6.91104 17.2586ZM17.3751 8.74007C17.3751 10.2679 16.133 11.51 14.6052 11.51C13.8725 11.5071 13.1707 11.2139 12.6537 10.6948C12.1366 10.1757 11.8463 9.47279 11.8463 8.74007C11.8448 8.37736 11.9152 8.01795 12.0533 7.68257C12.1915 7.34719 12.3946 7.04247 12.6511 6.78599C12.9076 6.52952 13.2123 6.32635 13.5477 6.18822C13.883 6.05009 14.2425 5.97972 14.6052 5.98117C15.3379 5.98117 16.0408 6.27149 16.5599 6.78857C17.079 7.30565 17.3722 8.00736 17.3751 8.74007ZM12.5387 8.74007C12.5387 9.8832 13.462 10.8175 14.6162 10.8175C15.7593 10.8175 16.6826 9.8832 16.6826 8.74007C16.6826 7.59694 15.7593 6.66265 14.6162 6.66265C13.462 6.66265 12.5387 7.59694 12.5387 8.74007Z"
                                                    fill="#7F7F7F"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="site-bot-accounts-header-text">{accounts?.other?.length + accounts?.empty?.length + accounts?.used?.length + accounts?.buying?.length ?? 0} Accounts</div>
                                </div>
                                <div className="site-bot-accounts-header-right">
                                    <div className="site-bot-accounts-header-right-number">
                                        <div className="site-bot-accounts-header-right-number-inner">${(balance / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="site-bot-accounts-body">
                                <div className="site-bot-accounts-stat">
                                    <div className="site-bot-accounts-stat-left">Full</div>
                                    <div className="site-bot-accounts-stat-right">{accounts?.used?.length ?? 0}</div>
                                </div>
                                <div className="site-bot-accounts-stat">
                                    <div className="site-bot-accounts-stat-left">Empty</div>
                                    <div className="site-bot-accounts-stat-right">{accounts?.empty?.length ?? 0}</div>
                                </div>
                                <div className="site-bot-accounts-stat">
                                    <div className="site-bot-accounts-stat-left">Other</div>
                                    <div className="site-bot-accounts-stat-right">{accounts?.other?.length ?? 0}</div>
                                </div>
                                <div className="site-bot-accounts-stat">
                                    <div className="site-bot-accounts-stat-left">Total</div>
                                    <div className="site-bot-accounts-stat-right">{accounts?.other?.length + accounts?.empty?.length + accounts?.used?.length + accounts?.buying?.length ?? 0}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="site-bot-loading">
                        <div className="site-bot-loading-inner">
                            <LogoSvg />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default BotStatus;
