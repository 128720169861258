const phaseMap = {
    "Phase 1": "P1",
    "Phase 2": "P2",
    "Phase 3": "P3",
    "Phase 4": "P4",
    Emerald: "Emerald",
    Ruby: "Ruby",
    Sapphire: "Sapphire",
    "Black Pearl": "BP",
};

function phaseToAbbreviation(phase) {
    return phaseMap[phase];
}

function extractCondition(item) {
    //parse csgo item names
    if (!item) return [null, null, null];
    if (item.includes("(Factory New)")) {
        return [item.replace("(Factory New)", ""), item.replace("(Factory New)", "").replace("StatTrak™", "ST™"), "Factory New", "FN"];
    } else if (item.includes("(Minimal Wear)")) {
        return [item.replace("(Minimal Wear)", ""), item.replace("(Minimal Wear)", "").replace("StatTrak™", "ST™"), "Minimal Wear", "MW"];
    } else if (item.includes("(Field-Tested)")) {
        return [item.replace("(Field-Tested)", ""), item.replace("(Field-Tested)", "").replace("StatTrak™", "ST™"), "Field-Tested", "FT"];
    } else if (item.includes("(Well-Worn)")) {
        return [item.replace("(Well-Worn)", ""), item.replace("(Well-Worn)", "").replace("StatTrak™", "ST™"), "Well-Worn", "WW"];
    } else if (item.includes("(Battle-Scarred)")) {
        return [item.replace("(Battle-Scarred)", ""), item.replace("(Battle-Scarred)", "").replace("StatTrak™", "ST™"), "Battle-Scarred", "BS"];
    } else {
        return [item, item.replace("StatTrak™", "ST™"), null, null];
    }
}

function getImageUrl(iconUrl) {
    if (!iconUrl) return iconUrl;
    if (iconUrl.slice(0, 4) != "http") {
        return "https://community.cloudflare.steamstatic.com/economy/image/" + iconUrl;
    } else {
        return iconUrl;
    }
}

function dateToTradableString(date) {
    if (!date) return null;
    let currentDate = new Date();
    let targetDate = new Date(date);
    let timeDifference = targetDate.getTime() - currentDate.getTime();

    let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    let hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    return days + "d " + hours + "h";
}

function toDollarFormat(number) {
    if (!number) return null;
    return "$" + (number / 100).toLocaleString(undefined, { minimumFractionDigits: 2 });
}

export { phaseToAbbreviation, extractCondition, getImageUrl, dateToTradableString, toDollarFormat };
