import React, { useState, useEffect } from "react";
import { Cookies } from "react-cookie";
import { VictoryChart, VictoryLine, VictoryAxis, VictoryLabel, VictoryTooltip, VictoryVoronoiContainer } from "victory";

import { SoldSvg, PurchasedSvg, ProfitSvg, PercentProfitSvg } from "../svgs/Statistics";
import LogoSvg from "../svgs/Logo";
import { rangeToTime } from "../helpers/time";

const statColorMap = {
    sold: "#65638A",
    purchased: "#06A1F8",
    profit: "#26D07F",
};

const chartTheme = {
    axis: {
        style: {
            tickLabels: {
                // this changed the color of my numbers to white
                fill: "white",
            },
        },
    },
};

function OverviewChart() {
    const [statisticsRange, setStatisticsRange] = useState("7d");
    const [chartStatistics, setChartStatistics] = useState(["sold", "purchased", "profit"]);
    const [chartData, setChartData] = useState({ sold: [], purchased: [], profit: [] });
    const [period, setPeriod] = useState("daily"); // ["daily", "weekly", "monthly"
    const cookies = new Cookies();
    const token = cookies.get("token");

    const [isLoading, setIsLoading] = useState(false);

    const getChartMax = () => {
        let max = 0;
        Object.keys(chartData).forEach((type) => {
            const typeMax = Math.max(...chartData[type].map((data) => data.y));
            if (typeMax > max) {
                max = typeMax;
            }
        });
        max = Math.ceil(max / 1000) * 1000;
        return max;
    };

    const getChartMin = () => {
        let min = 0;
        Object.keys(chartData).forEach((type) => {
            const typeMin = Math.min(...chartData[type].map((data) => data.y));
            if (typeMin < min) {
                min = typeMin;
            }
        });
        min = Math.floor(min / 1000) * 1000;
        return Math.min(min, 0);
    };

    const getTickValues = () => {
        let min = getChartMin();
        let max = getChartMax();
        let tickValues = [];
        for (let i = min; i <= max; i += 2000) {
            tickValues.push(i);
        }
        return tickValues;
    };

    const parseChartData = (data, type) => {
        let typeData = Object.keys(data).map((key) => {
            return { x: new Date(key), y: data[key] / 100 };
        });
        typeData.sort((a, b) => a.x - b.x);

        return typeData;
    };

    const getChartData = async (type) => {
        try {
            const url = process.env.REACT_APP_TRANSACTIONS_API_BASE_URL + "/v1/history/aggregate";
            let query = `?after=${rangeToTime(statisticsRange)}}`;
            query += `&stat=price`;
            query += `&statType=${type}`;
            query += `&period=daily`;
            const res = await fetch(url + query, {
                method: "GET",
                headers: {
                    "x-api-key": cookies.get("token"),
                },
            });
            const reqData = await res.json();
            const { success, data } = reqData;
            if (success) {
                return data;
            }
            return [];
        } catch (error) {
            return [];
        }
    };

    const getChart = async () => {
        if (isLoading) {
            return; // Return early if the previous call is still running
        }
        try {
            setIsLoading(true);
            let promises = chartStatistics.map(async (type) => {
                const data = await getChartData(type);
                const parsedData = parseChartData(data, type);
                return { type, data: parsedData };
            });

            const chartDataArray = await Promise.all(promises);
            const newChartData = chartDataArray.reduce((acc, { type, data }) => {
                acc[type] = data;
                return acc;
            }, {});

            setChartData(newChartData);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getChart();
    }, [token, statisticsRange]);

    return (
        <div className="panel-section">
            <div className="panel-section-header">Overview</div>
            <div className="panel-section-selection">
                <div className="panel-section-selection-left">
                    <div className="panel-section-selection-header">Time Period:</div>
                    <div className={`panel-section-button ${statisticsRange === "7d" ? "active" : ""}`} onClick={() => setStatisticsRange("7d")}>
                        7d
                    </div>
                    <div className={`panel-section-button ${statisticsRange === "30d" ? "active" : ""}`} onClick={() => setStatisticsRange("30d")}>
                        30d
                    </div>
                    <div className={`panel-section-button ${statisticsRange === "Mtd" ? "active" : ""}`} onClick={() => setStatisticsRange("Mtd")}>
                        Mtd
                    </div>
                    <div className={`panel-section-button ${statisticsRange === "All-time" ? "active" : ""}`} onClick={() => setStatisticsRange("All-time")}>
                        All-Time
                    </div>
                </div>

                <div className="panel-section-selection-right">
                    <div className="chart-stats">
                        {toggleStat("purchased", chartStatistics, setChartStatistics)}
                        {toggleStat("sold", chartStatistics, setChartStatistics)}
                        {toggleStat("profit", chartStatistics, setChartStatistics)}
                    </div>
                    <div className="panel-section-button" onClick={getChart}>
                        Refresh
                    </div>
                </div>
            </div>
            <div className="chart">
                {isLoading ? (
                    <div className="chart-loading">
                        <LogoSvg />
                    </div>
                ) : (
                    <>
                        <VictoryChart
                            style={{ parent: { maxWidth: "100%" } }}
                            width={1300}
                            height={660}
                            scale={{ x: "time" }}
                            containerComponent={
                                <VictoryVoronoiContainer
                                    voronoiDimension="x"
                                    labels={({ datum }) => {
                                        let value = datum.y;
                                        let stat = Object.keys(statColorMap).find((key) => statColorMap[key] == datum.style.data.stroke);
                                        return `Total ${uppercaseWord(stat)}: $${value.toLocaleString()}`;
                                    }}
                                    labelComponent={
                                        <VictoryTooltip
                                            cornerRadius={2}
                                            flyoutStyle={{
                                                fill: "white",
                                                border: "1px solid rgba(255, 255, 255, 0.10)",
                                                background: "#FFF",
                                                backdropFilter: "blur(5px)",
                                            }}
                                        />
                                    }
                                />
                            }
                        >
                            {Object.keys(chartData).map((type) => {
                                return chartStatistics.includes(type) && <VictoryLine data={chartData[type]} style={{ data: { stroke: `${statColorMap[type]}`, strokeWidth: "1px" } }} />;
                            })}
                            <VictoryAxis
                                offsetY={50}
                                tickCount={Math.min(chartData.purchased.length, 7)}
                                style={{
                                    tickLabels: { fill: "#7F7F7F" },
                                    grid: { stroke: "#FFFFFF1A", strokeWidth: 0.5, strokeDasharray: "6 6" },
                                }}
                            />
                            <VictoryAxis
                                dependentAxis
                                tickFormat={(x) => `$${x.toLocaleString(undefined, { maximumFractionDigits: 0 })}`}
                                style={{ tickLabels: { fill: "#F2F2F2" }, grid: { stroke: "#FFFFFF1A", strokeWidth: 0.5 } }}
                                domain={[getChartMin(), getChartMax()]}
                                tickCount={Math.ceil(Math.abs(getChartMax()) + Math.abs(getChartMin())) / 2000}
                                tickValues={getTickValues()}
                            />
                        </VictoryChart>
                    </>
                )}
            </div>
        </div>
    );
}

function toggleStat(stat, chartStatistics, setChartStatistics) {
    return (
        <div className="chart-stat" onClick={() => handleToggleStat(stat, chartStatistics, setChartStatistics)}>
            <div className="chart-stat-button">
                <div className="chart-stat-inner" style={{ backgroundColor: chartStatistics.includes(stat) ? statColorMap[stat] : "" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                        <path d="M2.24121 7.12695L4.81152 9.69727L9.95215 4.18945" stroke="#14131F" stroke-width="1.10156" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            </div>
            <div className="chart-stat-text">Total {uppercaseWord(stat)}</div>
        </div>
    );
}

function handleToggleStat(stat, chartStatistics, setChartStatistics) {
    console.log("HERE");
    if (chartStatistics.includes(stat)) {
        setChartStatistics(chartStatistics.filter((s) => s !== stat));
    } else {
        setChartStatistics([...chartStatistics, stat]);
    }
}

function uppercaseWord(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export default OverviewChart;
