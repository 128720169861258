import React, { useState, useEffect } from "react";
import { PanelRowSearch } from "../svgs/PanelRow";
import { Cookies } from "react-cookie";
import { rangeToTime } from "../helpers/time";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TransactionsTable from "../components/TransactionsTable";

const columns = [
    { name: "Account", width: "10%", key: "account", sortable: false },
    { name: "Item Name", width: "30%", key: "itemName", sortable: true },
    { name: "Journey", width: "15%", key: "journey", sortable: false },
    { name: "Purch Price", width: "7.5%", key: "purchasePrice", sortable: true },
    { name: "Purch Time", width: "10%", key: "purchaseTime", sortable: true },
    { name: "Sale Price", width: "7.5%", key: "salePrice", sortable: true },
    { name: "Sale Time", width: "10%", key: "saleTime", sortable: true },
    { name: "Profit", width: "10%", key: "profit", sortable: true },
];

function Transactions({ siteImages }) {
    const pageSize = 10;
    const [rows, setRows] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [pages, setPages] = useState(0);
    const [currPage, setCurrPage] = useState(1);
    const [nameSearch, setNameSearch] = useState("");
    const [sortBy, setSortBy] = useState("saleTime");
    const [sort, setSort] = useState("desc");
    const [sold, setSold] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [buySite, setBuySite] = useState("all");
    const [sellSite, setSellSite] = useState("all");

    const cookies = new Cookies();
    const token = cookies.get("token");
    const getTransactions = async () => {
        if (isLoading) {
            return;
        }
        try {
            setIsLoading(true);
            const url = process.env.REACT_APP_TRANSACTIONS_API_BASE_URL + "/v1/history/";

            let startTime = startDate;
            startTime.setHours(0, 0, 0, 0);
            startTime = startTime.getTime();

            let endTime = endDate;
            endTime.setHours(23, 59, 59, 999);
            endTime = endTime.getTime();

            let query = `?after=${startTime}`;
            query += `&before=${endTime}`;
            query += `&sortBy=${sortBy}`;
            query += `&sort=${sort}`;
            query += `&pageSize=${pageSize}`;
            query += `&page=${currPage}`;
            if (buySite && buySite != "all") query += `&purchaseLocation=${buySite}`;
            if (sellSite && sellSite != "all") query += `&saleLocation=${sellSite}`;
            if (nameSearch && nameSearch != "") query += `&name=${nameSearch}`;
            if (sold) query += `&sold=${sold}`;
            const res = await fetch(url + query, {
                method: "GET",
                headers: {
                    "x-api-key": cookies.get("token"),
                },
            });
            const reqData = await res.json();
            const { success, data } = reqData;
            if (success) {
                setRows(data?.transactions);
                setTotalItems(data?.totalItems);
                setTotalValue(data?.totalValue);
                setPages(Math.ceil(data?.totalItems / pageSize));
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getTransactions();
    }, [token, sold, sortBy, sort, startDate, endDate, nameSearch, buySite, sellSite, currPage]);

    return (
        <div className="panel-wrapper">
            <div className="panel-row">
                <div className="panel-row-left">
                    <div className="panel-row-title">Transactions</div>
                    <div className="panel-row-green-text">
                        {totalItems} Items for ${(totalValue / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </div>
                    <div className="panel-row-slider">
                        <div className="panel-row-slider-inactive">Bought</div>
                        <label className="switch">
                            <input type="checkbox" checked={sold} onClick={() => setSold(!sold)} readOnly />
                            <span className="slider round"></span>
                        </label>
                        <div className="panel-row-slider-active">Sold</div>
                    </div>
                </div>
                <div className="panel-row-right">
                    <div className="panel-section-button" onClick={getTransactions}>
                        Refresh
                    </div>
                    <div className="panel-row-search">
                        <PanelRowSearch />
                        <input className="panel-row-search-input" placeholder="Search by item name..." onBlur={(event) => setNameSearch(event.target.value)} />
                    </div>
                </div>
            </div>
            <div className="panel-row">
                <div className="panel-row-left">
                    <div className="panel-row-group">
                        From:
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                    </div>
                    <div className="panel-row-group">
                        To:
                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                    </div>
                </div>
                <div className="panel-row-right">
                    <div className="panel-row-group">
                        Filters:
                        <select className="panel-row-select" onChange={(e) => setBuySite(e.target.value)}>
                            <option selected="selected" value={"all"}>
                                Buy Site
                            </option>
                            {Object.keys(siteImages).map((site) => {
                                return <option value={site}>{site}</option>;
                            })}
                        </select>
                        <select className="panel-row-select" onChange={(e) => setSellSite(e.target.value)}>
                            <option selected="selected" value={"all"}>
                                Sell Site
                            </option>
                            {Object.keys(siteImages).map((site) => {
                                return <option value={site}>{site}</option>;
                            })}
                        </select>
                    </div>
                </div>
            </div>
            <TransactionsTable isLoading={isLoading} columns={columns} rows={rows} sort={sort} setSort={setSort} sortBy={sortBy} setSortBy={setSortBy} currPage={currPage} setCurrPage={setCurrPage} pages={pages} pageSize={pageSize} siteImages={siteImages} />
        </div>
    );
}

export default Transactions;
