import React, { useState } from "react";

function SiteIcon({ name, iconUrl }) {
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(name);
        setTooltipVisible(false);
    };

    return (
        <div className="site-icon" style={{ position: "relative" }}>
            <img src={iconUrl} />

            {tooltipVisible && (
                <div
                    style={{
                        position: "absolute",
                        top: "100%",
                        left: "50%",
                        transform: "translateX(-50%)",
                        backgroundColor: "black",
                        color: "white",
                        padding: "4px",
                        borderRadius: "4px",
                        fontSize: "12px",
                    }}
                >
                    {name}
                </div>
            )}

            <div
                style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                }}
                onMouseEnter={() => setTooltipVisible(true)}
                onMouseLeave={() => setTooltipVisible(false)}
                onClick={handleCopy}
            />
        </div>
    );
}

export default SiteIcon;
