import React, { useState, useEffect } from "react";
import { phaseToAbbreviation, extractCondition, getImageUrl, dateToTradableString } from "../helpers/item";
import { TradableSmall, UntradableSmall } from "../svgs/Tradable";
import { NavLink } from "react-router-dom";
import LogoSvg from "../svgs/Logo";

function ItemCard({ loading, siteImages, assetId, iconUrl, name, phase, tradable, tradableDate, price, purchasePrice, listings }) {
    const [parsedName, parsedNameShort, parsedCondition, parsedConditionShort] = extractCondition(name);

    return (
        <div className="inventory-item">
            {loading ? (
                <div className="inventory-item-loading">
                    <LogoSvg />
                </div>
            ) : (
                <>
                    <NavLink className="inventory-item-top" to={`/panel/inventory/${assetId}`}>
                        <img className="inventory-item-image" src={getImageUrl(iconUrl)} />
                        <div className="inventory-item-top-info">
                            <div className={tradable ? "green-background" : "red-background"}>
                                {tradable ? <TradableSmall /> : <UntradableSmall />}
                                {tradable ? null : dateToTradableString(tradableDate)}
                            </div>
                        </div>
                    </NavLink>
                    <div className="inventory-item-bottom">
                        <div className="inventory-item-bottom-inner">
                            <div className="inventory-item-bottom-name">
                                {parsedNameShort}
                                <div className="inventory-item-condition">
                                    {parsedConditionShort}
                                    {phase ? " · " : null}
                                    {phaseToAbbreviation(phase)}
                                </div>
                            </div>
                            <InventoryItemDivider />
                            <div className="inventory-item-bottom-values">
                                <div className="inventory-item-bottom-value">
                                    <div className="inventory-item-bottom-value-title green">Worth</div>
                                    <div className="inventory-item-bottom-value-value green">${(price / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
                                </div>
                                <div className="inventory-item-bottom-value">
                                    {purchasePrice && (
                                        <>
                                            <div className="inventory-item-bottom-value-title">Buy Price</div>
                                            <div className="inventory-item-bottom-value-value">${(purchasePrice / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <InventoryItemDivider />
                            <div className="inventory-item-bottom-listed">
                                Listed On
                                <div className="inventory-item-bottom-listed-sites">
                                    {listings?.map((listing) => {
                                        if (!listing?.site) return;
                                        if (!listing.ourPrice) return;
                                        const lowestClass = listing.ourPrice <= listing.lowestPrice ? "lowest" : "";
                                        return (
                                            <div className="inventory-item-bottom-listed-site">
                                                <img className={lowestClass} src={siteImages[listing?.site]} />${(listing?.ourPrice / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

function InventoryItemDivider() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="206" height="2" viewBox="0 0 206 2" fill="none">
            <rect x="0.981445" y="0.832947" width="204.865" height="0.857176" fill="#202023" />
        </svg>
    );
}

export default ItemCard;
