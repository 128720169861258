import React, { useState, useEffect } from "react";
import { Cookies } from "react-cookie";

import { getImageUrl } from "../helpers/item";
import { LiveFeedSvg, LiveFeedToggleSvg, LiveFeedBackgroundSvg, LiveFeedSaleSvg, LiveFeedPurchaseSvg } from "../svgs/LiveFeed";
function LiveFeed() {
    const [ws, setWs] = useState(null);
    const [liveFeedItems, setLiveFeedItems] = useState([]);
    const [showLiveFeed, setShowLiveFeed] = useState(false);

    const cookies = new Cookies();
    const token = cookies.get("token");

    const authenticate = (socket) => {
        socket.send(JSON.stringify({ action: "authenticate", token: token }));
    };

    const handleIncomingMessage = (message) => {
        if (message.action === "transaction") {
            if (liveFeedItems.length >= 20) {
                liveFeedItems.pop();
            }
            setLiveFeedItems([message?.data, ...liveFeedItems]);
        }
    };

    const getPreviousTransactions = async () => {
        try {
            const url = process.env.REACT_APP_TRANSACTIONS_API_BASE_URL + "/v1/transaction/recent";
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "x-api-key": token,
                },
            });
            const data = await res.json();
            const { success, transactions } = data;
            if (success) {
                console.log(transactions);
                setLiveFeedItems(transactions);
                console.log(liveFeedItems);
            }
        } catch (error) {}
    };

    useEffect(() => {
        getPreviousTransactions();
        const socket = new WebSocket(process.env.REACT_APP_TRANSACTIONS_WS_BASE_URL);

        socket.onopen = () => {
            console.log("WebSocket connection established");
            authenticate(socket);
        };

        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            handleIncomingMessage(data);
        };

        socket.onclose = () => {
            console.log("WebSocket connection closed");
        };

        socket.onerror = (error) => {
            console.error("WebSocket error: ", error);
        };

        return () => {
            socket.close();
        };
    }, [token]);

    const toggleLiveFeed = () => {
        setShowLiveFeed(!showLiveFeed);
    };

    return (
        <div className="live-feed">
            <div className="live-feed-header">
                <div className="live-feed-icon">
                    <LiveFeedSvg />
                </div>
                <div className="live-feed-text">Live Feed</div>
                <div className={`live-feed-toggle ${showLiveFeed ? "" : "active"}`} onClick={toggleLiveFeed}>
                    <LiveFeedToggleSvg />
                </div>
            </div>
            {showLiveFeed && (
                <div className="live-feed-content">
                    {liveFeedItems.reverse().map((item, index) => {
                        return liveFeedItem(item.iconUrl, item.phase, item.name, item.price, item.type);
                    })}
                </div>
            )}
        </div>
    );
}

function liveFeedItem(imgUrl, phase, name, price, type) {
    return (
        <div className="live-feed-item">
            <div className="live-feed-item-image">
                <img src={getImageUrl(imgUrl)} />
            </div>
            <div className="live-feed-item-price">${(price / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
            <div className="live-feed-item-type">
                <div className="live-feed-item-type-icon">{type === "sale" ? <LiveFeedSaleSvg /> : <LiveFeedPurchaseSvg />}</div>
                <div className="live-feed-item-type-text">{type === "sale" ? "Sale" : "Purchase"}</div>
            </div>
        </div>
    );
}

export default LiveFeed;
