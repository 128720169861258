import LogoSvg from "../svgs/Logo";

function StatBox(iconSvg, number, description, time, type = "number") {
    return (
        <div className="stat-box">
            {number != null ? (
                <div className="stat-box-inner">
                    <div className="stat-box-icon">
                        <svg width="47" height="46" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect
                                x="1.32739"
                                y="0.7948"
                                width="44.3345"
                                height="44.3345"
                                rx="22.1672"
                                fill="#D2E214"
                                fillOpacity="0.05"
                                stroke="#D2E214"
                                strokeWidth="0.75"
                            />
                        </svg>
                    </div>
                    <div className="stat-box-info">
                        <div className="stat-box-description">
                            {description} {time}
                        </div>
                        {type === "number" ? (
                            <div className="stat-box-number">${(number / 100).toLocaleString()}</div>
                        ) : type === "percent" ? (
                            <div className="stat-box-number">{(number * 100 - 100).toFixed(2)}%</div>
                        ) : type === "raw" ? (
                            <div className="stat-box-number">{number}</div>
                        ) : (
                            <div className="stat-box-number">{number}</div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="stat-box-loading">
                    <LogoSvg />
                </div>
            )}
        </div>
    );
}

export default StatBox;
