import React, { useState } from "react";
import { useCookies } from "react-cookie";

import LogoSvg from "../svgs/Logo.jsx";
import EmailSvg from "../svgs/Email.jsx";
import PasswordSvg from "../svgs/Password.jsx";
import TwoFaSvg from "../svgs/TwoFa.jsx";

function Login() {
    const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [twoFa, setTwoFa] = useState("");
    const [error, setError] = useState("");

    const onLoginClick = async () => {
        if (email === "" || password === "" || twoFa === "") {
            return;
        }

        const LOGIN_URL = process.env.REACT_APP_AUTH_API_BASE_URL + "/v1/auth/login";

        const res = await fetch(LOGIN_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
                totp: twoFa,
            }),
        });
        const data = await res.json();
        const { success, token, error } = data;
        if (success && token) {
            setCookie("token", token, { path: "/", expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) });
            window.location.href = "/";
        } else if (error) {
            setError(error);
        }
    };

    return (
        <div className="page">
            <img className="background-image" src="/background.png" />
            <span className="login-form">
                <div className="logo">
                    <LogoSvg />
                    <div className="logo-text">
                        LES<span className="logo-white-text">Trade</span>
                    </div>
                </div>
                <div className="form">
                    <div className="form-header">Welcome boss!</div>
                    <div className="form-content">
                        <div className="form-fields">
                            {FormField("Email", email, setEmail, <EmailSvg />)}
                            {FormField("Password", password, setPassword, <PasswordSvg />, true)}
                            {FormField("2FA Code", twoFa, setTwoFa, <TwoFaSvg />)}
                        </div>
                    </div>
                    <button className="button" onClick={onLoginClick}>
                        Log in
                    </button>
                    {error && <div className="form-field-error">{error}</div>}
                </div>
            </span>
        </div>
    );
}

function FormField(header, value, setValue, svg, hidden = false) {
    return (
        <div className="form-field">
            <div className="form-field-header">{header}</div>
            <div className="form-field-input">
                <div className="form-field-input-box">
                    <div className="form-field-input-label">
                        <div className="form-field-input-label-svg">{svg}</div>
                        <input
                            value={value}
                            className="form-field-input-label-text"
                            onChange={(ev) => setValue(ev.target.value)}
                            placeholder={`Enter your ${header}`}
                            type={hidden ? "password" : "text"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
