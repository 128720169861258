function LeftArrow() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
            <path
                d="M6.3048 3.94577C6.34749 3.98842 6.38137 4.03907 6.40448 4.09482C6.42759 4.15057 6.43948 4.21033 6.43948 4.27067C6.43948 4.33103 6.42759 4.39078 6.40448 4.44653C6.38137 4.50228 6.34749 4.55293 6.3048 4.59558L2.95527 7.94453L11.9499 7.94453C12.0717 7.94453 12.1885 7.99292 12.2746 8.07904C12.3608 8.16516 12.4091 8.28197 12.4091 8.40376C12.4091 8.52556 12.3608 8.64237 12.2746 8.72849C12.1885 8.81461 12.0717 8.863 11.9499 8.863L2.95527 8.86299L6.30479 12.2119C6.39097 12.2981 6.43938 12.415 6.43938 12.5369C6.43937 12.6587 6.39097 12.7756 6.30479 12.8618C6.21862 12.9479 6.10175 12.9963 5.97989 12.9963C5.85802 12.9963 5.74115 12.9479 5.65498 12.8618L1.52189 8.72867C1.4792 8.68602 1.44532 8.63537 1.42221 8.57962C1.3991 8.52387 1.38721 8.46411 1.38721 8.40376C1.38721 8.34341 1.3991 8.28365 1.42221 8.2279C1.44532 8.17215 1.4792 8.12151 1.52189 8.07886L5.65498 3.94577C5.69763 3.90307 5.74828 3.8692 5.80403 3.84609C5.85978 3.82298 5.91954 3.81108 5.97989 3.81108C6.04024 3.81108 6.1 3.82298 6.15575 3.84609C6.2115 3.8692 6.26215 3.90307 6.3048 3.94577Z"
                fill="#7F7F7F"
            />
        </svg>
    );
}

function RightArrow() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
            <path
                d="M8.88466 12.6021C8.84196 12.5594 8.80809 12.5088 8.78498 12.453C8.76187 12.3973 8.74997 12.3375 8.74997 12.2772C8.74997 12.2168 8.76187 12.1571 8.78498 12.1013C8.80809 12.0456 8.84196 11.9949 8.88466 11.9523L12.2342 8.60332L3.23955 8.60332C3.11776 8.60332 3.00095 8.55494 2.91482 8.46882C2.8287 8.38269 2.78032 8.26589 2.78032 8.14409C2.78032 8.02229 2.8287 7.90549 2.91482 7.81936C3.00095 7.73324 3.11776 7.68486 3.23955 7.68486L12.2342 7.68486L8.88466 4.33591C8.79849 4.24974 8.75008 4.13287 8.75008 4.011C8.75008 3.88914 8.79849 3.77227 8.88466 3.68609C8.97083 3.59992 9.0877 3.55151 9.20957 3.55151C9.33143 3.55151 9.4483 3.59992 9.53447 3.68609L13.6676 7.81918C13.7103 7.86183 13.7441 7.91248 13.7672 7.96823C13.7904 8.02398 13.8022 8.08374 13.8022 8.14409C13.8022 8.20444 13.7904 8.2642 13.7672 8.31995C13.7441 8.3757 13.7103 8.42635 13.6676 8.469L9.53447 12.6021C9.49182 12.6448 9.44117 12.6787 9.38542 12.7018C9.32967 12.7249 9.26992 12.7368 9.20957 12.7368C9.14922 12.7368 9.08946 12.7249 9.03371 12.7018C8.97796 12.6787 8.92731 12.6448 8.88466 12.6021Z"
                fill="#7F7F7F"
            />
        </svg>
    );
}

export { LeftArrow, RightArrow };
