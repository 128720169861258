import React, { useState, useEffect } from "react";
import { Cookies } from "react-cookie";
import { VictoryChart, VictoryLine, VictoryAxis, VictoryLabel, VictoryScatter, VictoryTooltip, VictoryVoronoiContainer, VictoryCursorContainer } from "victory";

import LogoSvg from "../svgs/Logo";
import { formatDate } from "../helpers/time";

const statColorMap = {
    sold: "#65638A",
    purchased: "#06A1F8",
    profit: "#26D07F",
};

function PriceChart({ name, phase, purchaseTime, purchasePrice }) {
    const [statisticsRange, setStatisticsRange] = useState("90d");
    const [chartData, setChartData] = useState([]);
    const cookies = new Cookies();
    const token = cookies.get("token");

    const [isLoading, setIsLoading] = useState(false);

    const getChartMax = () => {
        let max = Math.max(...chartData.map((data) => data.y));
        if (purchasePrice && new Date(purchaseTime) > getChartOldestDate()) {
            max = Math.max(max, purchasePrice / 100);
        }
        let multiplier = 1000;
        if (max < 1000) multiplier = 100;
        if (max < 100) multiplier = 10;
        if (max < 10) multiplier = 1;
        max = Math.ceil(max / multiplier) * multiplier;
        return max;
    };

    const getChartMin = () => {
        let min = Math.min(...chartData.map((data) => data.y));
        if (purchasePrice && new Date(purchaseTime) > getChartOldestDate()) {
            min = Math.min(min, purchasePrice / 100);
        }
        let multiplier = 1000;
        if (min < 1000) multiplier = 100;
        if (min < 100) multiplier = 10;
        if (min < 10) multiplier = 1;
        min = Math.floor(min / multiplier) * multiplier;
        return min;
    };

    const getChartOldestDate = () => {
        let oldestDate = new Date(Math.min(...chartData.map((data) => data.x)));
        return new Date(oldestDate);
    };

    const getTickValues = () => {
        let min = getChartMin();
        let max = getChartMax();
        let tickValues = [];
        for (let i = min; i <= max; i += 100) {
            tickValues.push(i);
        }
        return tickValues;
    };

    const getChartData = async (type) => {
        try {
            let url = `${process.env.REACT_APP_PRICING_API_BASE_URL}/v1/prices/history/${name}`;
            if (phase) url += `/${phase}`;
            let query = `?days=${statisticsRange.replace("d", "")}`;
            const res = await fetch(url + query, {
                method: "GET",
                headers: {
                    "x-api-key": token,
                },
            });
            const reqData = await res.json();
            const { success, data } = reqData;
            if (success) {
                return data;
            }
        } catch (error) {}
    };

    const getChart = async () => {
        if (isLoading) {
            return;
        }
        try {
            setIsLoading(true);
            const { dates, fmv } = await getChartData();
            let newChartData = [];
            for (let i = 0; i < dates.length; i++) {
                newChartData.push({ x: new Date(dates[i]), y: fmv[i] / 100 });
            }
            setChartData(newChartData);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (name === null || name === undefined) return;
        getChart();
    }, [token, statisticsRange, name, phase]);

    return (
        <div className="panel-section">
            <div className="panel-section-header">Price History</div>
            <div className="panel-section-selection">
                <div className="panel-section-selection-left">
                    <div className="panel-section-selection-header">Time Period:</div>
                    <div className={`panel-section-button ${statisticsRange === "1d" ? "active" : ""}`} onClick={() => setStatisticsRange("1d")}>
                        1d
                    </div>
                    <div className={`panel-section-button ${statisticsRange === "7d" ? "active" : ""}`} onClick={() => setStatisticsRange("7d")}>
                        7d
                    </div>
                    <div className={`panel-section-button ${statisticsRange === "30d" ? "active" : ""}`} onClick={() => setStatisticsRange("30d")}>
                        30d
                    </div>
                    <div className={`panel-section-button ${statisticsRange === "90d" ? "active" : ""}`} onClick={() => setStatisticsRange("90d")}>
                        90d
                    </div>
                </div>

                <div className="panel-section-selection-right">
                    <div className="panel-section-button" onClick={getChart}>
                        Refresh
                    </div>
                </div>
            </div>
            <div className="chart">
                {isLoading ? (
                    <div className="chart-loading">
                        <LogoSvg />
                    </div>
                ) : (
                    <>
                        <VictoryChart
                            style={{ parent: { maxWidth: "100%" } }}
                            width={1300}
                            height={660}
                            scale={{ x: "time" }}
                            containerComponent={
                                <VictoryVoronoiContainer
                                    voronoiDimension="x"
                                    labels={({ datum }) => {
                                        let value = datum.y;
                                        if (datum?.style?.data?.stroke == "#FFFFFF1A") return "";
                                        return `$${value}`;
                                    }}
                                    labelComponent={
                                        <VictoryTooltip
                                            cornerRadius={2}
                                            flyoutStyle={{
                                                fill: "white",
                                                border: "1px solid rgba(255, 255, 255, 0.10)",
                                                background: "#FFF",
                                                backdropFilter: "blur(5px)",
                                            }}
                                        />
                                    }
                                />
                            }
                        >
                            <VictoryLine data={chartData} style={{ data: { strokeWidth: "2px", stroke: "#d2e214" } }} />
                            <VictoryAxis
                                offsetY={50}
                                tickCount={Math.min(chartData.length, 7)}
                                style={{
                                    tickLabels: { fill: "#7F7F7F" },
                                    grid: { stroke: "#FFFFFF1A", strokeWidth: 0.5, strokeDasharray: "6 6" },
                                }}
                            />
                            <VictoryAxis
                                dependentAxis
                                tickFormat={(x) => `$${x.toLocaleString(undefined, { maximumFractionDigits: 0 })}`}
                                style={{ tickLabels: { fill: "#F2F2F2" }, grid: { stroke: "#FFFFFF1A", strokeWidth: 0.5 } }}
                                domain={[getChartMin(), getChartMax()]}
                                tickCount={Math.ceil(Math.abs(getChartMax()) + Math.abs(getChartMin())) / 100}
                                tickValues={getTickValues()}
                            />
                            {purchaseTime && purchasePrice && new Date(purchaseTime) > getChartOldestDate() && (
                                <VictoryLine
                                    data={[
                                        { x: new Date(purchaseTime), y: getChartMax() },
                                        { x: new Date(purchaseTime), y: getChartMin() },
                                    ]}
                                    style={{ data: { stroke: "#FFFFFF1A", strokeWidth: 2 } }}
                                />
                            )}
                            {purchaseTime && purchasePrice && new Date(purchaseTime) > getChartOldestDate() && <VictoryScatter data={[{ x: new Date(purchaseTime), y: purchasePrice / 100 }]} size={5} style={{ data: { fill: "#d2e214" } }} />}
                        </VictoryChart>
                    </>
                )}
            </div>
        </div>
    );
}

function uppercaseWord(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export default PriceChart;
